import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../_models/user';
import { Configuration } from '../app.constants';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    public userLogin = false;
    portalAPIUrl = '';
    constructor(private http: HttpClient, private configuration: Configuration) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.portalAPIUrl = configuration.SERVER.portalAPIUrl;
    }
    public get currentUserValue() {
        return this.currentUserSubject.value;
    }
    login(loginPayload, username) {
        const headers = {
            'Authorization': 'Basic ' + btoa('abbas:password'),
            'Content-type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
        };
        return this.http.post<any>(this.portalAPIUrl + '/oauth/token', loginPayload, { headers })
            .pipe(map(data => {
                this.userLogin = true;
                localStorage.setItem('currentUser', JSON.stringify(data));
                localStorage.setItem('userToken', data.access_token);
                localStorage.setItem('userName', username);
                this.currentUserSubject.next(data);
                return data;
            }));
    }
    getUserDetailByName(username) {
        return this.http.get<any>(this.portalAPIUrl + '/api/v1/private/findUser/' + username + '?access_token=' + localStorage.getItem('userToken'))
            .pipe(map(data => {
                return data;
            }));
    }
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userToken');
        localStorage.removeItem('doctorId');
        localStorage.removeItem('hospitalId');
        this.currentUserSubject.next(null);
    }

}
