import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../_services/authentication.service';

@Injectable()
export class OauthInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentUser = this.authenticationService.currentUserValue;
        // const currentUser = localStorage.getItem('currentUser');
        if (currentUser) {
            const token = localStorage.getItem('userToken');
            const request1 = request.clone({
                setHeaders: {
                    'content-type': 'application/json',
                    //Authorization: 'Bearer ' + token
                }
            });
            return next.handle(request1);
        } else {
            return next.handle(request);
        }
    }
}
