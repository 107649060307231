import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogComponent } from 'src/app/delete-dialog/delete-dialog.component';
import { MasterService } from '../../_services/masterservice.service';

@Component({
  selector: 'app-treatmentstatusmaster',
  templateUrl: './treatmentstatusmaster.component.html',
  styleUrls: ['./treatmentstatusmaster.component.css']
})
export class TreatmentstatusmasterComponent implements OnInit {
  error;
  treatmentList;
  submitted = false;
  disableSubmitBtn = false;
  treatmentMaster;
  constructor(private toastr: ToastrService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private dialog: MatDialog, private masterService: MasterService) { }
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  treatmentForm = this.fb.group({
    treatmentStatus: ['', Validators.required]
  });
  ngOnInit() {
    this.getAllTreatmentStatusList();
  }
  getAllTreatmentStatusList() {
    this.masterService.getAllTreatmentStatusList().subscribe(data => {
      this.treatmentList = data;
    });
  }

  submit() {
    this.submitted = true;
    if (this.treatmentForm.invalid) {
      return;
    }
    this.disableSubmitBtn = true;
    const data = this.treatmentForm.value;
    if (this.treatmentMaster) {
      data.id = this.treatmentMaster.id;
    }
    this.masterService.saveTreatmentStatus(data).subscribe(
      d => {
        this.disableSubmitBtn = false;
        this.getAllTreatmentStatusList();
        this.treatmentForm.controls.treatmentStatus.setValue('');
        if (this.treatmentMaster) {
          this.toastr.success('Update Successfully!', 'Update!');
        } else {
          this.toastr.success('Save Successfully!', 'Success!');
        }
        this.treatmentMaster = '';
        this.treatmentForm.reset();
        this.submitted = false;
      },
      e => {
        this.error = e.error.message;
      }
    );
  }

  getTreatmentStatusById(siteId) {
    this.masterService.getTreatmentStatusById(siteId).subscribe(data => {
      this.treatmentMaster = data;
      this.treatmentForm.controls.treatmentStatus.setValue(this.treatmentMaster.treatmentStatus);
    });
  }

}
