import { Component, OnInit, ElementRef, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerapiService } from '../serverapi.service';
import { OncocareDigitalService } from '../oncocaredigitalservice.service';
import { OncodatabaseserviceService } from '../oncodatabaseservice.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-doctorsregistration',
  templateUrl: './doctorsregistration.component.html',
  styleUrls: ['./doctorsregistration.component.css']
})
export class DoctorsregistrationComponent implements OnInit {
  @ViewChildren('input') vc;
  error;
  success = false;
  submitted = false;
  hospitalValidation = false;
  disableSubmitBtn = false;
  showhideHospitalArea = true;
  countriesSelect;
  statesSelect;
  districtsSelect;
  designationSelect;
  specialitySelect;
  servicesSelect;
  returnUrl = 'login';
  hospitalTypes = [
    { id: 'Cancer', name: 'Cancer' },
    { id: 'Multispeciality', name: 'Multispeciality' },
    { id: 'Other', name: 'Other' },
  ];
  genderList = [
    { id: 'Male', name: 'Male' },
    { id: 'Female', name: 'Female' },
  ];
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private serverapi: ServerapiService,
    private oncocareDigitalService: OncocareDigitalService,
    private oncodatabaseserviceService: OncodatabaseserviceService,
    private toastr: ToastrService
  ) { }
  registerForm = this.fb.group({
    doctorName: ['', [Validators.required, Validators.maxLength(255)]],
    gender: ['', Validators.required],
    designation: ['1', Validators.required],
    speciality: ['', Validators.required],
    cancerSubSpeciality: [''],
    registrationNo: ['', Validators.required],
    dateOfBirth: [''],
    password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(18)]],
    confirmPass: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(18)]],
    email: ['', [Validators.email, Validators.required]],
    countryCode: ['+91'],
    mobilenumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)
      , Validators.pattern('^[0-9]{10}$')]],
    hospitalAddressLine1: [''],
    isHospital: [''],
    hospitalName: ['', Validators.required],
    hospitalRegistrationNo: [''],
    chiefAdmin: ['Chief Admin', Validators.required],
    hospitalType: ['', Validators.required],
    serviceOffered: ['', Validators.required],
    hospitalCountry: ['101', Validators.required],
    hospitalState: ['', Validators.required],
    hospitalDistrict: ['', Validators.required],
    hospitalCity: [''],
    hospitalPincode: ['', Validators.required],
    hospitalEmail: ['test@gmail.com', [Validators.email]],
    alternativeHospitalEmail: ['', Validators.email],
    hospitalCountryCode: ['+91'],
    hospitalmobilenumber: ['0000000000', [Validators.required, Validators.minLength(10), Validators.maxLength(10)
      , Validators.pattern('^[0-9]{10}$')]],
    alternativeMobile: [''],
    alternativeLandline: [''],
    hospitalLandlineCode: ['+91'],
    hospitallandlinenumber: [''],
  });
  ngOnInit() {
    this.onLoadCall();
  }
  submit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.disableSubmitBtn = true;
    const data = this.registerForm.value;
    data.country = parseInt(data.country, 10);
    data.state = parseInt(data.state, 10);
    data.district = parseInt(data.district, 10);
    this.oncodatabaseserviceService.createOncocareDataBaselUser(data).subscribe(
      hisData => {
        data['hospitalId'] = hisData['data']['hospitalId'];
        data['userId'] = hisData['data']['userId'];
        // console.log(hisData.data.hospitalId);
        this.oncocareDigitalService.createOncocareDigitalUser(data).subscribe(
          cciData => {
            // Fixes #5901 -- doctor registration :- please change successful registration message.
            this.toastr.success('Doctor Registration Successfully!', 'Hospital');
            this.router.navigate([this.returnUrl]);
          },
          e => {
            this.error = e.error.message;
          }
        );
      },
      e => {
        this.error = e.error.message;
      }
    );
  }

  onLoadCall() {
    this.serverapi.getCountries().subscribe(
      data => {
        this.countriesSelect = data;
        this.updateState();
      }
    );
    this.serverapi.getServices().subscribe(
      data => {
        this.servicesSelect = data;
      }
    );
    this.serverapi.getDesignation().subscribe(
      data => {
        this.designationSelect = data;
      }
    );
    this.serverapi.getSpeciality().subscribe(
      data => {
        this.specialitySelect = data;
      }
    );
  }
  /*   showHideHospitalBox() {
      if (true) {
        this.showhideHospitalArea = true;
        this.addValidator('hospitalName', 0);
        this.addValidator('chiefAdmin', 0);
        this.addValidator('serviceOffered', 0);
        this.addValidator('hospitalCountry', 0);
        this.addValidator('hospitalState', 0);
        this.addValidator('hospitalDistrict', 0);
        this.addValidator('hospitalCity', 0);
        this.addValidator('hospitalPincode', 0);
        this.addValidator('hospitalEmail', 2);
        this.addValidator('alternativeHospitalEmail', 2);
        this.addValidator('hospitalmobilenumber', 1);
        this.addValidator('hospitallandlinenumber', 1);
        this.addValidator('hospitalType', 0);
        this.addValidator('hospitalAddressLine1', 0);
      } else {
        this.removeValidator('hospitalName');
        this.removeValidator('chiefAdmin');
        this.removeValidator('serviceOffered');
        this.removeValidator('hospitalCountry');
        this.removeValidator('hospitalState');
        this.removeValidator('hospitalDistrict');
        this.removeValidator('hospitalCity');
        this.removeValidator('hospitalPincode');
        this.removeValidator('hospitalEmail');
        this.removeValidator('hospitalmobilenumber');
        this.removeValidator('hospitallandlinenumber');
        this.removeValidator('hospitalType');
        this.removeValidator('hospitalAddressLine1');
        this.showhideHospitalArea = false;
      }
    } */

  updateState() {
    this.serverapi.getStates(this.registerForm.controls.hospitalCountry.value).subscribe(
      data => {
        this.statesSelect = data;
        this.registerForm.controls.hospitalState.setValue('12');
        this. updateDistrict();
      }
    );
  }

  updateDistrict() {
    this.serverapi.getDistricts(this.registerForm.controls.hospitalState.value).subscribe(
      data => {
        this.districtsSelect = data;
        this.registerForm.controls.district.setValue('');
      }
    );
  }

  addValidator(validatorname, flag) {
    if (flag === 1) {
      this.registerForm.controls[validatorname].setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10)
        , Validators.pattern('^[0-9]{10}$')]);
    } else if (flag === 2) {
      this.registerForm.controls[validatorname].setValidators([Validators.required, Validators.email]);
    } else {
      this.registerForm.controls[validatorname].setValidators([Validators.required]);
    }
    this.registerForm.controls[validatorname].updateValueAndValidity();
  }

  removeValidator(validatorname) {
    this.registerForm.get(validatorname).clearValidators();
    this.registerForm.get(validatorname).updateValueAndValidity();
  }


  checkPasswords(event) { // here we have the 'passwords' group
    const password = this.registerForm.controls.password.value;
    const confirmPass = this.registerForm.controls.confirmPass.value;
    if (password !== confirmPass && password.length > 0 && confirmPass.length > 0) {
      alert(' Password Not Match .');
      this.registerForm.controls.confirmPass.setValue('');
    }
  }

  isMobileExists() {
    this.serverapi.checkDuplicateMobileNumber(this.registerForm.controls.mobilenumber.value).subscribe(
      data => {
        if (data === true) {
          this.toastr.error('Mobile number already register as UserName !', 'User Name');
          this.registerForm.controls.mobilenumber.setValue('');
        }
      });
  }
}
