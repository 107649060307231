import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '../app.constants';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};
@Injectable({
  providedIn: 'root'
})
export class PatientService {
  portalUrl = '';
  constructor(private http: HttpClient, private configuration: Configuration) {
    this.portalUrl = this.configuration.SERVER.portalAPIUrl;
  }
  loginUser(data) {
    return this.http.post(this.portalUrl + '/api/v1/login', data, httpOptions).pipe();
  }
  saveToDoList(data: any) {
    return this.http.post(this.portalUrl + '/api/v1/addAppoinment', data, httpOptions).pipe();
  }

  findByAppointmentId(appointmentId) {
    return this.http.get(this.portalUrl + '/api/v1/findByAppointmentId/' + appointmentId).pipe();
  }

  getAllAppoinmentByPatientAndHospitalId(patientId, hospitalId) {
    return this.http.get(this.portalUrl + '/api/v1/getAllAppoinmentByPatientAndHospitalId/' + patientId + '/' + hospitalId).pipe();
  }

  updateAppointmentStatusById(appointmentId, status) {
    return this.http.get(this.portalUrl + '/api/v1/updateAppointmentStatusById/' + appointmentId + '/' + status).pipe();
  }
}
