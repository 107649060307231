import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mass-digital-pre-screening',
  templateUrl: './mass-digital-pre-screening.component.html',
  styleUrls: ['./mass-digital-pre-screening.component.css']
})
export class MassDigitalPreScreeningComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
  }

}
