import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ServerapiService } from '../serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../_services/masterservice.service';
import { SubscriptionService } from '../subscription/subscription.service';
import { OncocareDigitalService } from '../oncocaredigitalservice.service';

@Component({
  selector: 'app-patient-registration',
  templateUrl: './patient-registration.component.html',
  styleUrls: ['./patient-registration.component.css']
})
export class PatientRegistrationComponent implements OnInit {
  submitted = false;
  disableFlag = true;
  countryId = '';
  countryList;
  stateId = '';
  stateList;
  districtId = '';
  districtList;
  genderId = '';
  currentLoingUserId;
  patientSerach;
  readOnly = false;
  doctorList;
  patientDoctorHospitalList;
  doctorIdList = [];
  patientHospitalDoctorList;
  patientId = null;
  personId = null;
  userType;
  consultatDoctorId = '';
  stageId = '';
  siteId = '';
  subSiteId = '';
  subTypeId = '';
  lookingForId = '';
  treatmentHistoryId = '';
  treatmentStatusId = '';
  stageList;
  siteList;
  subSiteList;
  subTypeList;
  lookingForList;
  treatmentHistoryList;
  treatmentStatusList;
  patientTreatmentHistoryList;
  treatmentHistoryListId = [];
  isHavePatient = true;
  clinicList;
  clinicId = '';
  patientUnitsList;
  patientUnitsListId = [];
  patientStatus = 1;
  registerType;
  cancerSubsite;
  lookingFor;
  stage;
  cancerSubsiteId = '';
  lookingForIds = '';
  stageIds = '';

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private serverAPI: ServerapiService,
    private toastr: ToastrService,
    private masterService: MasterService,
    private subscriptionService: SubscriptionService,
    private oncocareDigitalService: OncocareDigitalService,
  ) {
    this.cancerSubsite = [
      { id: 'Throat ', name: 'Throat' },
      { id: 'Breast', name: 'Breast' },
      { id: 'Kidney', name: 'Kidney' },
      { id: 'Prostate', name: 'Prostate' },
      { id: 'Bladder', name: 'Bladder' },
      { id: 'Uterus', name: 'Uterus' },
      { id: 'Brain', name: 'Brain' },
      { id: 'Cervix', name: 'Cervix' },
      { id: 'Ovary', name: 'Ovary' },
      { id: 'Bone/SoftTissue', name: 'Bone/SoftTissue' },
      { id: 'Mouth', name: 'Mouth' },
      { id: 'Intestine', name: 'Intestine' },
      { id: 'Blood', name: 'Blood' },
      { id: 'Lung', name: 'Lung' },
      { id: 'Liver-G.B.', name: 'Liver-G.B.' },
      { id: 'Pancreas', name: 'Pancreas' },
      { id: 'Colon-Recturn', name: 'Colon-Recturn' },
      { id: 'Other', name: 'Other' },
    ];
    this.lookingFor = [
      { id: 'Treatment', name: 'Treatment' },
      { id: 'Rehabilitation', name: 'Rehabilitation' },
      { id: 'New Diagnosis', name: 'New Diagnosis' },
      { id: 'Palliation', name: 'Palliation' },
    ];
    this.stage = [
      { id: 'Early', name: 'Early' },
      { id: 'Advance', name: 'Advance' },
    ];
  }

  patientRegistrationForm = this.fb.group({
    firstName: ['', Validators.required],
    middleName: [''],
    lastName: ['', Validators.required],
    gender: ['', Validators.required],
    dateOfBirth: [''],
    age: ['', Validators.required],
    mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]{10}$')]],
    password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(18)]],
    confirmPass: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(18)]],
    alternativeMobileNumberOne: [''],
    alternativeMobileNumberTwo: [''],
    emailId: [''],
    countryCode: ['+91', Validators.required],
    country: ['101', Validators.required],
    state: ['', Validators.required],
    district: ['', Validators.required],
    city: [''],
    pincode: [''],
    address: [''],
    aadharNumber: [''],
    // From Doctor Site
    consultatDoctor: [''],
    stage: [''],
    site: [''],
    subSite: [''],
    subType: [''],
    lookingFor: [''],
    treatmentHistory: [''],
    treatmentStatus: [''],
    clinic: [''],
    patientStatus: [''],
    registerType: [this.activeRoute.snapshot.paramMap.get('registerType')],
    cancerSubsite: [''],
    lookingFors: [''],
    stages: [''],
    copunCode: [''],
  });

  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.registerType = this.activeRoute.snapshot.paramMap.get('registerType');
    this.personId = this.activeRoute.snapshot.paramMap.get('personId');
    this.patientId = this.activeRoute.snapshot.paramMap.get('patientId');
    this.currentLoingUserId = localStorage.getItem('currentLoingUserId');
    this.userType = localStorage.getItem('userType');
    if (this.userType === 'Doctor') {
      this.addValidator('consultatDoctor', 0);
      this.removeValidator('password');
    } else {
      this.removeValidator('consultatDoctor');
      this.addValidator('password', 3);
    }
    this.loadAllMaster();
    if (Number(this.patientId) > 0 && Number(this.personId) > 0) {
      this.getPatientById(this.personId, this.patientId);
    }
  }

  acceptTerms(event) {
    if (event.target.checked) {
      this.disableFlag = false;
    } else {
      this.disableFlag = true;
    }
  }

  get validMsg() {
    return this.patientRegistrationForm.controls;
  }

  getPatientCount(hospitalId) {
    this.subscriptionService.getPatientCount(hospitalId).subscribe(data => {
      localStorage.setItem('patientCount', data + ' ');
      if (Number(data) <= 0 && this.userType === 'Doctor') {
        this.isHavePatient = false;
      }
      this.submitted = true;
      if (this.patientRegistrationForm.invalid) {
        this.findInvalidControls();
        return;
      }
      if (this.userType === 'Doctor' && this.patientId !== '0' && this.personId !== '0' && this.patientId !== null && this.personId !== null) {
        this.bindPatientWithDoctor();
      } else {
        this.patientRegister();
      }
    });
  }

  goPreviousUrl() {
    history.go(-1);
  }

  submit() {
    if (localStorage.getItem('hospitalIdPortal')) {
      this.getPatientCount(localStorage.getItem('hospitalIdPortal'));
    } else {
      this.submitted = true;
      if (this.patientRegistrationForm.invalid) {
        return;
      }
      if (this.userType === 'Doctor' && this.patientId !== '0' && this.personId !== '0') {
        this.bindPatientWithDoctor();
      } else {
        this.patientRegister();
      }
      this.patientRegistrationForm.reset();
    }
    this.isMobileExists();

  }

  patientRegister() {
    this.disableFlag = true;
    const patientData = this.patientRegistrationForm.value;
    if (this.userType === 'Doctor') {
      patientData.hospitalId = localStorage.getItem('hospitalIdPortal');
      patientData.currentUserLoginId = localStorage.getItem('currentLoingUserId');
      patientData.currentUserName = localStorage.getItem('chiefAdmin');
      if (this.isHavePatient) {
        this.serverAPI.patientRegistration(patientData).subscribe(
          data => {
            console.log(data);
            this.toastr.success('Patient Registration Successfully!', 'Patient');
            this.resetForm();
            this.getPatientCountAfterRegistration(localStorage.getItem('hospitalIdPortal'));
            this.router.navigate(['/patient-list']);
          });
      } else {
        this.toastr.error('Your subscription plan is over so please renew.', 'Patient!');
      }
    } else {
      this.serverAPI.patientRegistration(patientData).subscribe(
        data => {
          console.log(data);
          this.toastr.success('Patient Registration Successfully!', 'Patient');
          this.resetForm();
          this.router.navigate(['/login']);
        });
    }
  }
  bindPatientWithDoctor() {
    const doctorIdAndHospitalId = this.patientRegistrationForm.value;
    doctorIdAndHospitalId.hospitalId = localStorage.getItem('hospitalIdPortal');
    doctorIdAndHospitalId.personId = this.personId;
    doctorIdAndHospitalId.patientId = this.patientId;
    doctorIdAndHospitalId.currentUserLoginId = localStorage.getItem('currentLoingUserId');
    doctorIdAndHospitalId.currentUserName = localStorage.getItem('chiefAdmin');
    if (this.isHavePatient) {
      this.serverAPI.bindPatientWithDoctorAndHospital(doctorIdAndHospitalId).subscribe(
        data => {
          if (data) {
            this.toastr.success('Patient Update Successfully!', 'Update');
            this.resetForm();
            this.getPatientCountAfterRegistration(localStorage.getItem('hospitalIdPortal'));
            this.router.navigate(['/patient-list']);
          }
        });
    } else {
      this.toastr.error('Your subscription plan is over so please renew.', 'Patient!');
    }
  }
  getPatientCountAfterRegistration(hospitalId) {
    this.subscriptionService.getPatientCount(hospitalId).subscribe(data => {
      localStorage.setItem('patientCount', data + ' ');
    });
  }
  getAllCountries() {
    this.serverAPI.getCountries().subscribe(
      data => {
        this.countryList = data;
        this.patientRegistrationForm.controls.country.setValue('101');
        this.getStateByCountryId();
      });
  }

  getStateByCountryId() {
    this.serverAPI.getStates(this.patientRegistrationForm.controls.country.value).subscribe(
      data => {
        this.stateList = data;
        this.patientRegistrationForm.controls.state.setValue('12');
        this.getDistrictByStateId();
      });
  }

  getDistrictByStateId() {
    this.serverAPI.getDistricts(this.patientRegistrationForm.controls.state.value).subscribe(
      data => {
        this.districtList = data;
      });
  }



  getDoctorByhospitalId() {
    this.serverAPI.getDoctorByhospitalId(localStorage.getItem('hospitalIdPortal')).subscribe(
      data => {
        this.doctorList = data;
      });
  }
  getPatientHospitalMappingByHospitalId(patientId, personId) {
    this.serverAPI.getPatientHospitalMappingByHospitalId(localStorage.getItem('hospitalIdPortal'), patientId, personId).subscribe(
      data => {
        if (data) {
          this.patientDoctorHospitalList = data;
          this.patientDoctorHospitalList.forEach(patientDoctorHospital => {
            this.doctorIdList.push(patientDoctorHospital['doctorId'].toString());
          });
          this.patientRegistrationForm.controls.consultatDoctor.setValue(this.doctorIdList);
          this.patientDoctorHospitalList = '';
        }
      });
  }

  getPatientByMobileOrEmailId() {
    if (this.patientSerach !== "" && this.patientSerach !== null) {
      this.serverAPI.getPatientByMobileOrEmailId(this.patientSerach).subscribe(
        data => {
          if (data['patient'] !== null) {
            this.setFormData(data);
          } else {
            this.resetForm();
          }
        });
    } else {
      this.resetForm();
    }
  }


  getPatientById(personId, patientId) {
    this.serverAPI.getPatientById(personId, patientId).subscribe(
      data => {
        if (data['patient'] !== null) {
          this.setFormData(data);
        } else {
          this.resetForm();
        }
      });
  }


  setFormData(data) {
    this.readOnly = true;
    this.patientRegistrationForm.controls.firstName.setValue(data['patient']['firstName']);
    this.patientRegistrationForm.controls.middleName.setValue(data['patient']['middleName']);
    this.patientRegistrationForm.controls.lastName.setValue(data['patient']['lastName']);
    this.patientRegistrationForm.controls.gender.setValue(data['patient']['sex']);
    this.patientRegistrationForm.controls.dateOfBirth.setValue(data['patient']['dateOfBirth']);
    this.patientRegistrationForm.controls.age.setValue(data['patient']['age']);
    this.patientRegistrationForm.controls.mobileNumber.setValue(data['patient']['mobileNo']);
    this.patientRegistrationForm.controls.alternativeMobileNumberOne.setValue(data['patient']['alternativeMobileNumberOne']);
    this.patientRegistrationForm.controls.alternativeMobileNumberTwo.setValue(data['patient']['alternativeMobileNumberTwo']);
    this.patientRegistrationForm.controls.emailId.setValue(data['patient']['emailId']);
    this.patientRegistrationForm.controls.patientStatus.setValue(data['patient']['patientStatus']);
    this.patientRegistrationForm.controls.countryCode.setValue(data['patient']['icdCode']);
    this.patientRegistrationForm.controls.country.setValue(data['patient']['countryId']);
    this.getStateByCountryId();
    this.patientRegistrationForm.controls.state.setValue(data['patient']['stateId']);
    this.getDistrictByStateId();
    this.patientRegistrationForm.controls.district.setValue(data['patient']['districtId']);
    this.getClinicByDistict();
    this.patientRegistrationForm.controls.city.setValue(data['patient']['city']);
    this.patientRegistrationForm.controls.pincode.setValue(data['patient']['pincode']);
    this.patientRegistrationForm.controls.address.setValue(data['patient']['address']);
    this.patientRegistrationForm.controls.aadharNumber.setValue(data['patient']['adharNo']);
    this.getPatientHospitalMappingByHospitalId(data['patient']['patientId'], data['person']['id']);
    this.getPatientBindHospitalDoctorList(data['patient']['patientId'], data['person']['id']);
    this.getPatientTreatmentHistoryList(data['patient']['patientId'], data['person']['id']);
    this.getPatientFutureByPatientIdAndPersonId(data['patient']['patientId'], data['person']['id']);
    this.getPersonUnitsByPersonId(data['person']['id']);
    this.patientId = data['patient']['patientId'];
    this.personId = data['person']['id'];
  }

  loadAllMaster() {
    if (Number(localStorage.getItem('hospitalIdPortal')) > 0) {
      this.getDoctorByhospitalId();
    }
    this.getAllCountries();
    this.getAllStageList();
    this.getAllSiteList();
    this.getAllSubSiteList();
    this.getAllSubTypeList();
    this.getAllLookingForList();
    this.getAllTreatmentHistoryList();
    this.getAllTreatmentStatusList();
  }
  getPatientBindHospitalDoctorList(patientId, personId) {
    this.serverAPI.getPatientBindHospitalDoctorList(localStorage.getItem('hospitalIdPortal'), patientId, personId).subscribe(
      data => {
        this.patientHospitalDoctorList = data;
      });
  }
  getAllStageList() {
    this.masterService.getAllStageList().subscribe(data => {
      this.stageList = data;
    });
  }

  getAllSiteList() {
    this.masterService.getAllSiteList().subscribe(data => {
      this.siteList = data;
    });
  }

  getAllSubSiteList() {
    this.masterService.getAllSubSiteList().subscribe(data => {
      this.subSiteList = data;
    });
  }


  getAllSubTypeList() {
    this.masterService.getAllSubTypeList().subscribe(data => {
      this.subTypeList = data;
    });
  }


  getAllLookingForList() {
    this.masterService.getAllLookingForList().subscribe(data => {
      this.lookingForList = data;
    });
  }

  getAllTreatmentHistoryList() {
    this.masterService.getAllTreatmentHistoryList().subscribe(data => {
      this.treatmentHistoryList = data;
    });
  }


  getAllTreatmentStatusList() {
    this.masterService.getAllTreatmentStatusList().subscribe(data => {
      this.treatmentStatusList = data;
    });
  }


  getPatientTreatmentHistoryList(patientId, personId) {
    this.serverAPI.getPatientTreatmentHistoryList(localStorage.getItem('hospitalIdPortal'), patientId, personId).subscribe(
      data => {
        if (data) {
          this.patientTreatmentHistoryList = data;
          this.patientTreatmentHistoryList.forEach(patientTreatmentHistory => {
            this.treatmentHistoryListId.push(patientTreatmentHistory[0].toString());
          });
          this.patientRegistrationForm.controls.treatmentHistory.setValue(this.treatmentHistoryListId);
          this.patientTreatmentHistoryList = '';
        }
      });
  }

  getPatientFutureByPatientIdAndPersonId(patientId, personId) {
    this.serverAPI.getPatientFutureByPatientIdAndPersonId(localStorage.getItem('hospitalIdPortal'), patientId, personId).subscribe(
      data => {
        if (data) {
          this.patientRegistrationForm.controls.stage.setValue(data[0][1]);
          this.patientRegistrationForm.controls.site.setValue(data[0][2]);
          this.patientRegistrationForm.controls.subSite.setValue(data[0][3]);
          this.patientRegistrationForm.controls.subType.setValue(data[0][4]);
          this.patientRegistrationForm.controls.lookingFor.setValue(data[0][5]);
          this.patientRegistrationForm.controls.treatmentStatus.setValue(data[0][6]);
        }
      });
  }

  resetForm() {
    // this.patientRegistrationForm.reset();
    this.readOnly = false;
    this.patientHospitalDoctorList = null;
    this.getDoctorByhospitalId();
    this.patientId = null;
    this.personId = null;
    this.patientRegistrationForm.controls.countryCode.setValue('+91');
    this.countryId = '';
    this.stateId = '';
    this.districtId = '';
    this.genderId = '';
    this.stageId = '';
    this.siteId = '';
    this.subSiteId = '';
    this.subTypeId = '';
    this.lookingForId = '';
    this.treatmentHistoryId = '';
    this.treatmentStatusId = '';
    this.disableFlag = false;
  }

  addValidator(validatorname, flag) {
    if (flag === 1) {
      this.patientRegistrationForm.controls[validatorname].setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10)
        , Validators.pattern('^[0-9]{10}$')]);
    } else if (flag === 2) {
      this.patientRegistrationForm.controls[validatorname].setValidators([Validators.required, Validators.email]);
    } else if (flag === 3) {
      this.patientRegistrationForm.controls[validatorname].setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(18)]);
    } else {
      this.patientRegistrationForm.controls[validatorname].setValidators([Validators.required]);
    }
    this.patientRegistrationForm.controls[validatorname].updateValueAndValidity();
  }

  removeValidator(validatorname) {
    this.patientRegistrationForm.get(validatorname).clearValidators();
    this.patientRegistrationForm.get(validatorname).updateValueAndValidity();
  }

  getClinicByDistict = () => {
    this.oncocareDigitalService.getUnitsByDistrict(this.patientRegistrationForm.controls.district.value).subscribe(
      data => {
        this.clinicList = data;
      }
    );
  }

  getPersonUnitsByPersonId(personId) {
    this.serverAPI.getPersonUnitsByPersonId(personId).subscribe(
      data => {
        if (data) {
          this.patientUnitsList = data;
          this.patientUnitsList.forEach(patientUnits => {
            this.patientUnitsListId.push(patientUnits[1].toString());
          });
          this.patientRegistrationForm.controls.clinic.setValue(this.patientUnitsListId);
          this.patientUnitsList = '';
        }
      });
  }


  getPatientCouponCode(event) {
    this.serverAPI.getPatientCouponCode().subscribe(data => {
      console.log(data);
      if (event.target.value !== '' && event.target.value !== data['coupponCode']) {
        this.toastr.error('Coupon Code Invalid Please Contact Admin Person!', 'Coupon Code');
        this.patientRegistrationForm.controls.copunCode.setValue('');
      }
    });
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.patientRegistrationForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        console.log(name);
        invalid.push(name);
      }
    }
    return invalid;
  }

  checkPasswords(event) { // here we have the 'passwords' group
    const password = this.patientRegistrationForm.controls.password.value;
    const confirmPass = this.patientRegistrationForm.controls.confirmPass.value;
    if (password !== confirmPass && password.length > 0 && confirmPass.length > 0) {
      alert(' Password Not Match .');
      this.patientRegistrationForm.controls.confirmPass.setValue('');
    }
  }
  isMobileExists() {
    this.serverAPI.checkDuplicateMobileNumber(this.patientRegistrationForm.controls.mobileNumber.value).subscribe(
      data => {
        if (data === true) {
          this.toastr.error('Mobile number already register as UserName !', 'User Name');
          this.patientRegistrationForm.controls.mobileNumber.setValue('');
        }
      });
  }
}
