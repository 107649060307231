import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
import { Configuration } from '../app.constants';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
        // 'Authorization': localStorage.getItem('userToken')
    })
};
@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {
    portalAPIUrl = '';
    constructor(private http: HttpClient, private configuration: Configuration) {
        this.portalAPIUrl = configuration.SERVER.portalAPIUrl;
    }

    getAllSubscriptionPlanForHospital() {
        return this.http.get(this.portalAPIUrl + '/api/v1/private/getAllSubscriptionPlanForHospital');
    }
    getAllSubscriptionPlan() {
        return this.http.get(this.portalAPIUrl + '/api/v1/private/getAllSubscriptionPlan');
    }


    getSubscription() {
        return this.http.get(this.portalAPIUrl + '/api/v1/private/getSubscriptionPlan/' + localStorage.getItem('hospitalIdPortal'));
    }
    buySubcription(subscriptionId) {
        const saveUrl = this.portalAPIUrl + '/api/v1/private/buySubscription/' + subscriptionId + '/' + localStorage.getItem('doctorId') + '/' + localStorage.getItem('hospitalIdPortal') + '/' + localStorage.getItem('currentLoingUserId');
        return this.http.post(saveUrl, httpOptions).pipe();
    }

    saveSubcriptionPlan(data) {
        return this.http.post(this.portalAPIUrl + '/api/v1/private/saveSubcriptionPlan', data, httpOptions).pipe();
    }
    getSubscriptionMasterById(subscriptionId) {
        return this.http.get(this.portalAPIUrl + '/api/v1/private/getSubscriptionPlanById/' + subscriptionId).pipe();
    }
    deleteSubscriptionMasterById(subscriptionId) {
        return this.http.get(this.portalAPIUrl + '/api/v1/private/deleteSubscriptionMasterById/' + subscriptionId).pipe();
    }
    deactiveSubscriptionMasterById(subscriptionId, status) {
        return this.http.get(this.portalAPIUrl + '/api/v1/private/deactiveSubscriptionMasterById/' + subscriptionId + '/' + status).pipe();
    }
    getPatientCount(hospitalId) {
        return this.http.get(this.portalAPIUrl + '/api/v1/private/getPatientCount/' + hospitalId).pipe();
    }
    getRegisterPatientCount(hospitalId) {
        return this.http.get(this.portalAPIUrl + '/api/v1/private/getRegisterPatientCount/' + hospitalId).pipe();
    }

    getMySubscriptionByHospitalId(hospitalId) {
        return this.http.get(this.portalAPIUrl + '/api/v1/private/getMySubscriptionByHospitalId/' + hospitalId).pipe();
    }
    checkSubscriptionPlanAssignHospital(subscriptionId) {
        return this.http.get(this.portalAPIUrl + '/api/v1/checkSubscriptionPlanAssignHospital/' + subscriptionId).pipe();
    }

    getCouponCodeByCouponCodeName(couponCodeName) {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/couponCodeByCouponCodeName/' + couponCodeName).pipe();
    }
}
