import { Component, OnInit } from '@angular/core';
import { Configuration } from 'src/app/app.constants';

@Component({
  selector: 'app-community-programme',
  templateUrl: './community-programme.component.html',
  styleUrls: ['./community-programme.component.css']
})
export class CommunityProgrammeComponent implements OnInit {

  constructor(
    private configuration: Configuration,
  ) { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
  }
  helthWorkerRedirection() {
    window.location.href = this.configuration.SERVER.oncocareDigitalApplicationUrl + '#/admin/doctors/outside';
  }
}
