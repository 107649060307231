import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ServerapiService } from '../serverapi.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  otp;
  mobileNumber;

  changePasswordForm = this.formBuilder.group({
    newpassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
    confirmpassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
    userName: [''],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ForgetPasswordComponent>,
    private formBuilder: FormBuilder,
    private serverapiService: ServerapiService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.otp = this.data.otp;
    this.mobileNumber = this.data.mobilenumber;
    this.changePasswordForm.controls.userName.setValue(this.mobileNumber);
  }

  onSubmit() {
    const datas = this.changePasswordForm.value;
    this.serverapiService.resetPassword(datas).subscribe(
      data => {
        if (data.status === '200') {
          this.toastr.success('Password Change Successfully!', 'Success!');
          this.dialogRef.close();
        } else {
          this.toastr.error(data.message, 'Error!');
        }
      },
      error => {
      }
    );
  }

  checkPasswords(event) { // here we have the 'passwords' group
    const password = this.changePasswordForm.controls.newpassword.value;
    const confirmPass = this.changePasswordForm.controls.confirmpassword.value;
    if (password !== confirmPass && password.length > 0 && confirmPass.length > 0) {
      alert(' Password Not Match .');
      this.changePasswordForm.controls.confirmpassword.setValue('');
    }
  }
}
