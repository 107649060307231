import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AddEditAppoinmentComponent } from '../add-edit-appoinment/add-edit-appoinment.component';
import { AddEditFunctionalOutcomeComponent } from '../add-edit-functional-outcome/add-edit-functional-outcome.component';
import { Configuration } from '../app.constants';
import { Router } from '@angular/router';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit {
  patientId;
  personId;
  dtOptions: DataTables.Settings = {};
  patientList;
  portalUrl = '';
  message;
  hospitalId;
  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.portalUrl = this.configuration.SERVER.portalAPIUrl;
  }

  ngOnInit() {
    this.getPatientList();
  }


  getPatientList() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.hospitalId = localStorage.getItem('hospitalIdPortal');
        this.http
          .post<DataTablesResponse>(
            this.portalUrl + '/api/v1/patient/getPatientByCriterion',
            dataTablesParameters, {}
          ).subscribe(resp => {
            this.patientList = resp.data;
            console.log(this.patientList);
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: [],
            });
          });
      },
      columns: [{ data: 'patientName' }, { data: 'mobileNumber' }]
    };
  }

  addEditAppoinment(patientId, personId) {
    this.message = 'Once you Delete, all your data will be removed. Continue ?';
    const dialogRef = this.dialog.open(AddEditAppoinmentComponent, { height: '600px', width: '768px', data: { patientId, personId } });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {

      }
    });
  }

  addEditFunctionalOutCome(patientId, personId) {
    const dialogRef = this.dialog.open(AddEditFunctionalOutcomeComponent, { height: '600px', width: '768px', data: { patientId, personId } });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        console.log(result);
      }
    });
  }

  goToOncocareDigitalPatientDashboard(personId) {
    localStorage.setItem('patIdApp', personId);
    localStorage.setItem('flag', 'fromPortal');
    window.location.href = this.configuration.SERVER.oncocareDigitalApplicationUrl + '#/doctor/patient';
  }

  goToDatabasePatientDashboard(patientId) {
    localStorage.setItem('portalPatientId', patientId);
    window.location.href = this.configuration.SERVER.oncocareDatabaseApplicationUrl + '#/app/user/user-dashboard';
  }

  editPatientById(personId , patientId) {
    this.router.navigate(['/patient-registration/' + personId + '/' + patientId + '/' + 0 ]);
  }


}
