import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refund-cancle-component',
  templateUrl: './refund-cancle-component.component.html',
  styleUrls: ['./refund-cancle-component.component.css']
})
export class RefundCancleComponentComponent implements OnInit {

  isLogin = 'false';
  privacyPolicyClass;
  constructor() { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.isLogin = localStorage.getItem('isLogin');
    this.setClass();
  }

  setClass() {
    if (this.isLogin === 'true') {
      this.privacyPolicyClass = 'privacy-m-top';
    } else {
      this.privacyPolicyClass = 'privacy-policy-main';
    }
  }

}
