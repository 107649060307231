import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from './app.constants';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class OncodatabaseserviceService {
  private oncoDBUrl = '';
  constructor(private http: HttpClient, private configuration: Configuration) {
    this.oncoDBUrl = configuration.SERVER.oncocareDatabaseAPIUrl;
  }

  createOncocareDataBaselUser(data) {
    const url = this.oncoDBUrl + 'unsecure/saveUserFromPortal';
    return this.http.post(url, data, httpOptions).pipe();
  }


  hisLogin(data) {
    const url = this.oncoDBUrl + 'unsecure/login/';
    return this.http.post(url, data, httpOptions).pipe();
  }

  saveContactForm(data) {
    return this.http.post(this.oncoDBUrl + 'unsecure/saveContactForm', data, httpOptions).pipe();
  }
}
