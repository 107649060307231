import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-find-cancer-expert-near-you',
  templateUrl: './find-cancer-expert-near-you.component.html',
  styleUrls: ['./find-cancer-expert-near-you.component.css']
})
export class FindCancerExpertNearYouComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
  }

}
