import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-personalised-assistance-programme',
  templateUrl: './personalised-assistance-programme.component.html',
  styleUrls: ['./personalised-assistance-programme.component.css']
})
export class PersonalisedAssistanceProgrammeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
  }

}
