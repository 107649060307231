import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from './app.constants';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  })
};

@Injectable({
  providedIn: 'root'
})
export class OncocareDigitalService {
  private oncocareDigitalUrl = '';
  constructor(private http: HttpClient, private configuration: Configuration) {
    this.oncocareDigitalUrl = configuration.SERVER.oncocareDigitalAPIUrl;
  }

  createOncocareDigitalUser(data) {
    const url = this.oncocareDigitalUrl + 'api/v1/portal/saveUserFromPortal';
    return this.http.post(url, data, httpOptions).pipe();
  }

  /* Appoinment Related Code Start */

  getPersonById(id): any {
    return this.http.get(this.oncocareDigitalUrl + 'getpersonbyid/' + id).pipe();
  }
  getAllHeadingMasterByheadingFor(headingFor) {
    return this.http.get(this.oncocareDigitalUrl + '/api/v1/master/getallheadingmasterbyheadingfor/' + headingFor).pipe();
  }

  saveToDoList(data: any) {
    return this.http.post(this.oncocareDigitalUrl + '/api/v1/todolist/addtodolist', data, httpOptions).pipe();
  }

  getAllToDoList() {
    return this.http.get(this.oncocareDigitalUrl + '/api/v1/todolist/getalltodolist').pipe();
  }

  getAllToDoListByLoginId(userLoginId) {
    return this.http.get(this.oncocareDigitalUrl + '/api/v1/todolist/getalltodolistbyloginUserId/' + userLoginId).pipe();
  }

  getToDoListById(id): any {
    return this.http.get(this.oncocareDigitalUrl + '/api/v1/todolist/gettodolistbyid/' + id).pipe();
  }

  deleteToDoListById(id): any {
    return this.http.get(this.oncocareDigitalUrl + '/api/v1/todolist/deletetodolistbyid/' + id).pipe();
  }

  statusChangeToDoListById(id, status): any {
    return this.http.get(this.oncocareDigitalUrl + '/api/v1/todolist/statuschangestodolistbyid/' + id + '/' + status).pipe();
  }

  getAllActivityMasterByHeadingId(id): any {
    return this.http.get(this.oncocareDigitalUrl + '/api/v1/todolist/getallactivitymasterbyheadingid/' + id).pipe();
  }

  getAllActivityMasterByHeadingIdAndActivityFor(id, activityFor): any {
    return this.http.get(this.oncocareDigitalUrl + '/api/v1/todolist/getallactivitymasterbyheadingidandactivityfor/' + id + '/' + activityFor).pipe();
  }

  getToDoListByStatus(status, loginUserId): any {
    return this.http.get(this.oncocareDigitalUrl + '/api/v1/todolist/gettodolistbystatus/' + status + '/' + loginUserId).pipe();
  }

  getToDoListByCriteria(data): any {
    return this.http.post(this.oncocareDigitalUrl + '/api/v1/todolist/gettodolistbycriteria', data, httpOptions).pipe();
  }
  /* Appoinment Related Code  End */


  /* Functional Out Come Form Master Start */

  getAllFunctionalOutComeFormByCategory(categoryId) {
    return this.http.get(this.oncocareDigitalUrl + '/api/v1/master/getallfunctionaloutcomeformByCategoryId/' + categoryId).pipe();
  }

  getAllTreatmentMaster() {
    return this.http.get(this.oncocareDigitalUrl + '/api/v1/master/getalltreatmentmaster').pipe();
  }

  getAllCurrentIssueMaster() {
    return this.http.get(this.oncocareDigitalUrl + '/api/v1/master/getallcurrentissuemaster').pipe();
  }

  saveFunctionalOutcomePatient(data: any) {
    return this.http.post(this.oncocareDigitalUrl + '/api/v1/master/addfunctionalformforpatient', data, httpOptions).pipe();
  }

  getFunctionalOutcomePatientByPatientId(patientId): any {
    return this.http.get(this.oncocareDigitalUrl + '/api/v1/master/getfunctionalformforpatientbyid/' + patientId).pipe();
  }
  getFunctionalFormForPatientByIdGroupby(id): any {
    return this.http.get(this.oncocareDigitalUrl + '/api/v1/master/getfunctionalformforpatientbyidgroupby/' + id).pipe();
  }
  getAllFunctionalOutComeCategory() {
    return this.http.get(this.oncocareDigitalUrl + '/api/v1/master/getallfunctionaloutcomecategory').pipe();
  }

  /* Functional Out Come Form Master End */


  getUnitsByDistrict(districtid) {
    return this.http.get(this.oncocareDigitalUrl + '/api/v1/district/' + districtid + '/units');
  }


  cciLogin(data): any {
    return this.http.post(this.oncocareDigitalUrl + 'api/v1/login', data, httpOptions).pipe();
  }
}
