import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from '../subscription/subscription.service';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogComponent } from 'src/app/delete-dialog/delete-dialog.component';


@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.css']
})
export class SubscriptionPlanComponent implements OnInit {
  public Editor = ClassicEditor;
  error;
  submitted = false;
  disableSubmitBtn = false;
  subscriptionList;
  subscriptionMaster;
  message;
  constructor(private toastr: ToastrService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private subscriptionService: SubscriptionService, private dialog: MatDialog) { }
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  registerForm = this.fb.group({
    subscriptionName: ['', Validators.required],
    patientCount: ['', Validators.required],
    subscriptionType: ['', Validators.required],
    duration: ['', Validators.required],
    total: ['', Validators.required],
    description: ['', Validators.required]
  });
  ngOnInit() {
    this.getAllSubscriptionPlan();
  }
  getAllSubscriptionPlan() {
    this.subscriptionService.getAllSubscriptionPlan().subscribe(data => {
      this.subscriptionList = data;
    });
  }
  submit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.disableSubmitBtn = true;
    const data = this.registerForm.value;
    if (this.subscriptionMaster) {
      data.id = this.subscriptionMaster.id;
    }
    this.subscriptionService.saveSubcriptionPlan(data).subscribe(
      d => {
        this.disableSubmitBtn = false;
        this.getAllSubscriptionPlan();
        this.registerForm.controls.subscriptionName.setValue('');
        this.registerForm.controls.patientCount.setValue('');
        this.registerForm.controls.subscriptionType.setValue('');
        this.registerForm.controls.duration.setValue('');
        this.registerForm.controls.total.setValue('');
        this.registerForm.controls.description.setValue('');
        if (this.subscriptionMaster) {
          this.toastr.success('Update Successfully!', 'Update!');
        } else {
          this.toastr.success('Save Successfully!', 'Success!');
        }
        this.subscriptionMaster = '';
      },
      e => {
        this.error = e.error.message;
      }
    );
  }
  getSubscriptionMasterById(subscriptionId) {
    this.subscriptionService.getSubscriptionMasterById(subscriptionId).subscribe(data => {
      this.subscriptionMaster = data;
      this.registerForm.controls.subscriptionName.setValue(this.subscriptionMaster.subscriptionName);
      this.registerForm.controls.patientCount.setValue(this.subscriptionMaster.patientCount);
      this.registerForm.controls.subscriptionType.setValue(this.subscriptionMaster.subscriptionType);
      this.registerForm.controls.duration.setValue(this.subscriptionMaster.duration);
      this.registerForm.controls.total.setValue(this.subscriptionMaster.total);
      this.registerForm.controls.description.setValue(this.subscriptionMaster.description);
    });
  }

  deleteSubscriptionMasterById(id) {
    this.message = 'Once you Delete, all your data will be removed. Continue ?';
    const dialogRef = this.dialog.open(DeleteDialogComponent, { data: { message: this.message, btn: 'Delete', btn2: 'Cancel' } });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.subscriptionService.deleteSubscriptionMasterById(id).subscribe(
          data => {
            this.toastr.success('Delete Successfully!', 'Success!');
            this.getAllSubscriptionPlan();
          }
        );
      }
    });
  }

  deactiveSubscriptionMasterById(id, status) {
    if (status === 0) {
      this.message = 'are you sure deactive this subscription plan ?';
    } else {
      this.message = 'are you sure active this subscription plan ?';
    }
    const dialogRef = this.dialog.open(DeleteDialogComponent, { data: { message: this.message, btn: 'Yes', btn2: 'No' } });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.subscriptionService.deactiveSubscriptionMasterById(id, status).subscribe(
          data => {
            if (status === 1) {
              this.toastr.success('Active Successfully!', 'Success!');
            } else {
              this.toastr.success('Deactive Successfully!', 'Success!');
            }
            this.getAllSubscriptionPlan();
          }
        );
      }
    });
  }


  checkSubscriptionPlanAssignHospital(subscriptionId, flag) {
    this.subscriptionService.checkSubscriptionPlanAssignHospital(subscriptionId).subscribe(data => {
      this.subscriptionList = data;
      if (this.subscriptionList.length === 0) {
        if (flag === 2) {
          this.deleteSubscriptionMasterById(subscriptionId);
        } else {
          this.deactiveSubscriptionMasterById(subscriptionId, flag);
        }
      } else {
        if (flag === 2) {
          this.toastr.error('Subscription plan already assign hospital you can not delete !', 'warning!');
        } else {
          this.toastr.error('Subscription plan already assign hospital you can not active or deactive plan !', 'warning!');
        }
        this.getAllSubscriptionPlan();
      }
    });
  }



}
