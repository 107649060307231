import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from './app.constants';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  })
};
@Injectable({
  providedIn: 'root'
})
export class ServerapiService {
  portalUrl = '';
  private oncoDBUrl = '';
  constructor(private http: HttpClient, private configuration: Configuration) {
    this.portalUrl = this.configuration.SERVER.portalAPIUrl;
    this.oncoDBUrl = this.configuration.SERVER.oncocareDatabaseAPIUrl;
  }


  loginUser(data) {
    return this.http.post(this.portalUrl + '/api/v1/login', data, httpOptions).pipe();
  }

  subscriptionDetails(userId) {
    return this.http.get(this.portalUrl + '/api/v1/subscriptiondetails/' + userId).pipe();
  }

  getDesignation() {
    return this.http.get(this.portalUrl + '/api/v1/location/designation');
  }
  getServices() {
    return this.http.get(this.portalUrl + '/api/v1/location/services');
  }
  getSpeciality() {
    return this.http.get(this.portalUrl + '/api/v1/location/speciality');
  }
  getCountries() {
    return this.http.get(this.portalUrl + '/api/v1/location/country');
  }
  getStates(countryid) {
    return this.http.get(this.portalUrl + '/api/v1/location/state/' + countryid);
  }
  getDistricts(stateid) {
    return this.http.get(this.portalUrl + '/api/v1/location/district/' + stateid);
  }
  getDistrictsbyid(id) {
    return this.http.get(this.portalUrl + 'api/v1/location/districtbyid/' + id);
  }
  postRegistration(data) {
    return this.http.post(this.portalUrl + '/api/v1/registration/saveDoctors', data, httpOptions).pipe();
  }
  getUserFullDetailByName(username) {
    return this.http.get(this.portalUrl + '/api/v1/registration/findUserDetails/' + username);
  }
  updateUserLoginFlag(username) {
    return this.http.get(this.portalUrl + '/api/v1/registration/updateUserLoginFlag/' + username);
  }

  patientRegistration(data) {
    return this.http.post(this.portalUrl + '/api/v1/patient/register', data, httpOptions).pipe();
  }

  checkDuplicateMobileNumber(mobileNumber) {
    return this.http.get(this.portalUrl + '/api/v1/common/checkDuplicateMobileNumber/' + mobileNumber);
  }


  getPatientByMobileOrEmailId(searchBy) {
    return this.http.get(this.portalUrl + '/api/v1/patient/getPatientByMobileOrEmailId/' + searchBy);
  }


  getPatientById(personId, patientId) {
    return this.http.get(this.portalUrl + '/api/v1/patient/getPatientId/' + personId + '/' + patientId);
  }

  getDoctorByhospitalId(hospitalId) {
    return this.http.get(this.portalUrl + '/api/v1/patient/getDoctorByhospitalId/' + hospitalId);
  }

  getPatientHospitalMappingByHospitalId(hospitalId, patientId, personId) {
    return this.http.get(this.portalUrl + '/api/v1/patient/getPatientHospitalMappingByHospitalId/' + hospitalId + '/' + patientId + '/' + personId);
  }


  getPatientBindHospitalDoctorList(hospitalId, patientId, personId) {
    return this.http.get(this.portalUrl + '/api/v1/patient/getPatientBindHospitalDoctorList/' + hospitalId + '/' + patientId + '/' + personId);
  }

  bindPatientWithDoctorAndHospital(data) {
    return this.http.post(this.portalUrl + '/api/v1/patient/bindPatientWithDoctorAndHospital', data, httpOptions).pipe();
  }

  getPatientTreatmentHistoryList(hospitalId, patientId, personId) {
    return this.http.get(this.portalUrl + '/api/v1/patient/getPatientTreatmentHistoryListById/' + hospitalId + '/' + patientId + '/' + personId);
  }


  getPatientFutureByPatientIdAndPersonId(hospitalId, patientId, personId) {
    return this.http.get(this.portalUrl + '/api/v1/patient/getPatientFutureByPatientIdAndPersonId/' + hospitalId + '/' + patientId + '/' + personId);
  }

  getUserByUserId(userId) {
    return this.http.get(this.portalUrl + '/api/v1/common/getUserByUserId/' + userId);
  }

  getPatientSummaryByUserId(userId) {
    return this.http.get(this.portalUrl + '/api/v1/patient/getPatientSummaryByUserId/' + userId);
  }

  getPatientSubscriptionPlanByCCISubscriptionId(subscriptionId) {
    return this.http.get(this.portalUrl + '/portal/api/v1/patient/getPatientSubscriptionPlanByCCISubscriptionId/' + subscriptionId);
  }

  getPersonUnitsByPersonId(personId) {
    return this.http.get(this.portalUrl + '/api/v1/patient/getPersonUnitsByPersonId/' + personId);
  }

  sendOtp(mobileNo): any {
    return this.http.get(this.portalUrl + '/portalapi/sendOtp/' + mobileNo);
  }
  verifyOtp(mobileNo, personId): any {
    return this.http.get(this.portalUrl + '/portalapi/verifyOtp/' + mobileNo + '/' + personId);
  }

  changePassword(data): any {
    return this.http.post(this.portalUrl + '/portalapi/api/v1/changePassword', data, httpOptions).pipe();
  }
  resetPassword(data): any {
    return this.http.post(this.portalUrl + '/portalapi/api/v1/resetpassword', data, httpOptions).pipe();
  }
  getPatientCouponCode() {
    return this.http.get(this.portalUrl + '/portalapi/api/v1/common/getPatientCouponCode').pipe();
  }
}
