import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-self-screening-static-page',
  templateUrl: './self-screening-static-page.component.html',
  styleUrls: ['./self-screening-static-page.component.css']
})
export class SelfScreeningStaticPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
  }

}
