import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../subscription/subscription.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-subscription-summary',
  templateUrl: './subscription-summary.component.html',
  styleUrls: ['./subscription-summary.component.css']
})
export class SubscriptionSummaryComponent implements OnInit {
  subscriptionDetails;
  subscriptionId;

  constructor(private subscriptionService: SubscriptionService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getSubscriptionMasterById();
  }


  getSubscriptionMasterById() {
    this.subscriptionService.getSubscriptionMasterById(localStorage.getItem('buyedSubscriptionId')).subscribe(data => {
      this.subscriptionDetails = data;
    });
  }
}
