import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-component',
  templateUrl: './privacy-policy-component.component.html',
  styleUrls: ['./privacy-policy-component.component.css']
})
export class PrivacyPolicyComponentComponent implements OnInit {
  isLogin = 'false';
  privacyPolicyClass;
  constructor() { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.isLogin = localStorage.getItem('isLogin');
    this.setClass();
  }


  setClass() {
    if (this.isLogin === 'true') {
      this.privacyPolicyClass = 'privacy-m-top';
    } else {
      this.privacyPolicyClass = 'privacy-policy-main';
    }
  }

}
