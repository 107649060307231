import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { ServerapiService } from '../serverapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login-singup',
  templateUrl: './login-singup.component.html',
  styleUrls: ['./login-singup.component.css']
})
export class LoginSingupComponent implements OnInit {
  error;
  submitted;
  subscriptionId;
  loginForm = this.fb.group({
    username: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    password: ['', [Validators.required, Validators.minLength(8)]]
  });
  constructor(
    private fb: FormBuilder,
    private serverAPI: ServerapiService,
    private router: Router,
    private toastr: ToastrService,
    private activeRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.subscriptionId = this.activeRoute.snapshot.paramMap.get('subscriptionId');
    localStorage.setItem('buyedSubscriptionId', this.subscriptionId);
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const body = new HttpParams()
      .set('username', this.loginForm.controls.username.value)
      .set('password', this.loginForm.controls.password.value)
      .set('grant_type', 'password');
    localStorage.setItem('userName', this.loginForm.controls.username.value);
    localStorage.setItem('password', this.loginForm.controls.password.value);
    localStorage.setItem('portalUserName', this.loginForm.controls.username.value);
    localStorage.setItem('portalPassword', this.loginForm.controls.password.value);
    const credential = {
      userName: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value,
    };
    this.serverAPI.loginUser(credential).subscribe(data => {
      if (data != null) {
        localStorage.setItem('hospitalIdPortal', data[0]);
        localStorage.setItem('chiefAdmin', data[1]);
        localStorage.setItem('isSubscription', data[2]);
        localStorage.setItem('firstName', data[3]);
        localStorage.setItem('isSuperAdmin', data[6]);
        localStorage.setItem('isHospitalAdmin', data[7]);
        localStorage.setItem('currentLoingUserId', data[8]);
        localStorage.setItem('doctorId', data[9]);
        localStorage.setItem('userType', data[10]);
        this.router.navigate(['/payment/' + localStorage.getItem('currentLoingUserId') + '/' + this.subscriptionId]);
      } else {
        this.toastr.error('Username or password is invalid!', 'login!');
      }

    },
      error => {
        this.error = 'Username or password is invalid!';
      });
  }

}
