import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CouponCodeService } from './coupon-code.service';
import { SubscriptionService } from '../subscription/subscription.service';
import { MatDialog } from '@angular/material';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-coupon-code-master',
  templateUrl: './coupon-code-master.component.html',
  styleUrls: ['./coupon-code-master.component.css']
})
export class CouponCodeMasterComponent implements OnInit {
  error;
  couponCodeList;
  submitted = false;
  disableSubmitBtn = false;
  couponCodeMaster;
  activeSubscriptionList;
  message;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private couponCodeService: CouponCodeService,
    private subscriptionService: SubscriptionService,
    private dialog: MatDialog,
  ) { }

  couponCodeForm = this.fb.group({
    couponCodeName: ['', Validators.required],
    coupunCodeFor: ['0', Validators.required],
    discountPrice: ['', Validators.required],
    numberOfUser: ['0'],
    discountBase: ['', Validators.required],
  });

  ngOnInit() {
    this.onLoanCallMethods();
  }

  onLoanCallMethods() {
    this.getAllSubscriptionPlan();
    this.getAllCouponCodePlan();
  }

  submit() {
    this.submitted = true;
    if (this.couponCodeForm.invalid) {
      return;
    }
    this.disableSubmitBtn = true;
    const data = this.couponCodeForm.value;
    if (this.couponCodeMaster) {
      data.couponCodeId = this.couponCodeMaster.couponCodeId;
    }
    this.couponCodeService.saveCouponCodePlan(data).subscribe(
      response => {
        this.getAllCouponCodePlan();
        this.couponCodeForm.controls.couponCodeName.setValue('');
        this.couponCodeForm.controls.coupunCodeFor.setValue('0');
        this.couponCodeForm.controls.discountPrice.setValue('');
        this.couponCodeForm.controls.numberOfUser.setValue('');
        this.disableSubmitBtn = false;
        if (this.couponCodeMaster) {
          this.toastr.success('Update Successfully!', 'Update!');
        } else {
          this.toastr.success('Save Successfully!', 'Success!');
        }
        this.couponCodeMaster = '';
      },
      e => {
        this.error = e.error.message;
      });
  }

  getAllCouponCodePlan() {
    this.couponCodeService.getAllCouponCodePlan().subscribe(
      response => {
        this.couponCodeList = response;
      },
      e => {
        this.error = e.error.message;
      });
  }

  getAllSubscriptionPlan() {
    this.subscriptionService.getAllSubscriptionPlanForHospital().subscribe(response => {
      this.activeSubscriptionList = response;
    });
  }

  getCouponCodePlanMasterById(couponCodeId) {
    this.couponCodeService.getCouponCodePlanMasterById(couponCodeId).subscribe(response => {
      this.couponCodeMaster = response;
      this.couponCodeForm.controls.couponCodeName.setValue(this.couponCodeMaster.couponCodeName);
      this.couponCodeForm.controls.coupunCodeFor.setValue(this.couponCodeMaster.coupunCodeFor);
      this.couponCodeForm.controls.discountPrice.setValue(this.couponCodeMaster.discountPrice);
      this.couponCodeForm.controls.numberOfUser.setValue(this.couponCodeMaster.numberOfUser);
      this.couponCodeForm.controls.discountBase.setValue(this.couponCodeMaster.discountBase);
    });
  }


  deleteCouponCodePlanById(id) {
    this.message = 'Once you Delete, all your data will be removed. Continue ?';
    const dialogRef = this.dialog.open(DeleteDialogComponent, { data: { message: this.message, btn: 'Delete', btn2: 'Cancel' } });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.couponCodeService.deleteCouponCodePlanById(id).subscribe(
          data => {
            this.toastr.success('Delete Successfully!', 'Success!');
            this.getAllCouponCodePlan();
          }
        );
      }
    });
  }

  deactiveCouponCodePlanById(id, status) {
    if (status === 0) {
      this.message = 'are you sure deactive this coupon code plan  ?';
    } else {
      this.message = 'are you sure active this coupon code plan ?';
    }
    const dialogRef = this.dialog.open(DeleteDialogComponent, { data: { message: this.message, btn: 'Yes', btn2: 'No' } });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.couponCodeService.deactiveCouponCodePlanById(id, status).subscribe(
          data => {
            if (status === 1) {
              this.toastr.success('Deactive Successfully!', 'Success!');
            } else {
              this.toastr.success('Active Successfully!', 'Success!');
            }
            this.getAllCouponCodePlan();
          }
        );
      }
    });
  }
}
