import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '../app.constants';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        // 'Authorization': localStorage.getItem('userToken')
    })
};
@Injectable({
    providedIn: 'root'
})
export class MasterService {
    portalAPIUrl = '';
    constructor(private http: HttpClient, private configuration: Configuration) {
        this.portalAPIUrl = configuration.SERVER.portalAPIUrl;
    }
    getAllStageList() {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/stageslist');
    }
    getStageMasterById(stageId) {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/stagesbyid/' + stageId).pipe();
    }
    saveStageMaster(data) {
        return this.http.post(this.portalAPIUrl + '/api/v1/master/savestages', data, httpOptions).pipe();
    }
    getAllSiteList() {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/sitelist');
    }
    getSiteMasterById(stageId) {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/sitebyid/' + stageId).pipe();
    }
    saveSiteMaster(data) {
        return this.http.post(this.portalAPIUrl + '/api/v1/master/savesite', data, httpOptions).pipe();
    }
    getAllSubSiteList() {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/subsitelist');
    }
    getSubSiteMasterById(stageId) {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/subsitebyid/' + stageId).pipe();
    }
    saveSubSiteMaster(data) {
        return this.http.post(this.portalAPIUrl + '/api/v1/master/savesubsite', data, httpOptions).pipe();
    }
    getAllLookingForList() {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/lookingforlist');
    }
    getLookingForMasterById(lookId) {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/lookingforbyid/' + lookId).pipe();
    }
    saveLookingForMaster(data) {
        return this.http.post(this.portalAPIUrl + '/api/v1/master/savelookingfor', data, httpOptions).pipe();
    }
    getAllSubTypeList() {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/subtypelist');
    }
    getSubTypeMasterById(lookId) {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/subtypebyid/' + lookId).pipe();
    }
    saveSubTypeMaster(data) {
        return this.http.post(this.portalAPIUrl + '/api/v1/master/savesubtype', data, httpOptions).pipe();
    }
    getAllTreatmentHistoryList() {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/treatmenthistorylist');
    }
    getTreatmentHistoryById(lookId) {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/treatmenthistorybyid/' + lookId).pipe();
    }
    saveTreatmentHistory(data) {
        return this.http.post(this.portalAPIUrl + '/api/v1/master/savetreatmenthistory', data, httpOptions).pipe();
    }
    getAllTreatmentStatusList() {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/treatmentstatuslist');
    }
    getTreatmentStatusById(lookId) {

        return this.http.get(this.portalAPIUrl + '/api/v1/master/treatmentstatusbyid/' + lookId).pipe();
    }
    saveTreatmentStatus(data) {
        return this.http.post(this.portalAPIUrl + '/api/v1/master/savetreatmentstatus', data, httpOptions).pipe();
    }

}
