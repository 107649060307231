import { Component, OnInit, Inject, Input } from '@angular/core';
import { AppComponent } from '../app.component';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { SubscriptionService } from '../subscription/subscription.service';
import { PaymentService } from '../payment/payment.service';
import { ServerapiService } from '../serverapi.service';


@Component({
  selector: 'app-patient-payment',
  templateUrl: './patient-payment.component.html',
  styleUrls: ['./patient-payment.component.css']
})
export class PatientPaymentComponent implements OnInit {
  message;
  CCISubscriptionId = '';
  subscriptionPlan;
  patientDetails;
  constructor(
    private paymentService: PaymentService,
    private myCom: AppComponent,
    private dialog: MatDialog,
    private ServerAPI: ServerapiService,
  ) { }

  ngOnInit() {
    this.CCISubscriptionId = localStorage.getItem('CCISubscriptionId');
    this.getPatientByUserId();
    this.myCom.setLoginValues();
    if (this.CCISubscriptionId !== undefined && this.CCISubscriptionId !== '' && this.CCISubscriptionId !== null) {
      this.getPatientSubscriptionPlanByCCISubscriptionId();
    }
  }

  getPatientByUserId() {
    this.ServerAPI.getPatientSummaryByUserId(localStorage.getItem('currentLoingUserId')).subscribe(
      data => {
        this.patientDetails = data[0];
        if (this.CCISubscriptionId === undefined || this.CCISubscriptionId === '' || this.CCISubscriptionId === null) {
          this.CCISubscriptionId = data[0][5];
          this.getPatientSubscriptionPlanByCCISubscriptionId();
        }
      });
  }


  getPatientSubscriptionPlanByCCISubscriptionId() {
    this.ServerAPI.getPatientSubscriptionPlanByCCISubscriptionId(this.CCISubscriptionId).subscribe(
      data => {
        this.subscriptionPlan = data;
      });
  }

  buyNow() {
    this.message = 'Are you sure want to buy this plan ?';
    const dialogRef = this.dialog.open(DeleteDialogComponent, { data: { message: this.message, btn: 'Buy Plan', btn2: 'Cancel' } });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        const paymentData = {
          hospitalId: 0,
          doctorId: 0,
          currentUserLoginId: localStorage.getItem('currentLoingUserId'),
          subscriptionId: this.subscriptionPlan.id,
          doctorsubscriptionId: 0,
          amount: this.subscriptionPlan.price,
        };
        this.paymentService.postPaytmPayment(paymentData).subscribe(
          responseOfPaytm => {
            console.log(responseOfPaytm);
            this.paymentService.paytmRedirection(responseOfPaytm);
          });
      }
    });
  }


}
