import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from '../app.component';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { SubscriptionService } from '../subscription/subscription.service';
import { PaymentService } from './payment.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  subscriptionDetails;
  subscriptionId;
  loginUserId;
  message;
  couponCodeResponse;
  couponCode;
  finalPaybelAmout;
  subscriptionPaybelAmout;
  constructor(
    private subscriptionService: SubscriptionService,
    private activeRoute: ActivatedRoute,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private router: Router,
    private appComponent: AppComponent,
    private paymentService: PaymentService,
  ) { }

  ngOnInit() {
    this.subscriptionId = this.activeRoute.snapshot.paramMap.get('subscriptionId');
    this.loginUserId = this.activeRoute.snapshot.paramMap.get('loginUserId');
    this.appComponent.setLoginValues();
    this.getSubscriptionMasterById();
  }

  getSubscriptionMasterById() {
    this.subscriptionService.getSubscriptionMasterById(this.subscriptionId).subscribe(data => {
      this.subscriptionDetails = data;
      this.subscriptionPaybelAmout = data['total'];
      this.finalPaybelAmout = data['total'];
    });
  }

  getCouponCodeByCouponCodeName() {
    this.subscriptionService.getCouponCodeByCouponCodeName(this.couponCode).subscribe(data => {
      this.couponCodeResponse = data;
      if (data) {
        if (data['coupunCodeFor'] === 0 || data['coupunCodeFor'] === Number(this.subscriptionId)) {
          if (data['couponCodeName'] === this.couponCode) {
            if (data['discountBase'] === 'flatdiscount') {
              this.finalPaybelAmout = this.subscriptionPaybelAmout - data['discountPrice'];
            } else if (data['discountBase'] === 'inpersentagediscount') {
              this.finalPaybelAmout = this.subscriptionPaybelAmout - ((this.subscriptionPaybelAmout * data['discountPrice']) / 100);
            }
            this.toastr.success('congratulation you will get discount', 'Success');
          }
        } else {
          this.finalPaybelAmout = this.subscriptionPaybelAmout;
          this.toastr.error('sorry this coupon code is not valid for this subscription plan contact admin person', 'Failed!');
        }
      } else {
        this.finalPaybelAmout = this.subscriptionPaybelAmout;
        this.toastr.error('sorry this coupon code is not valid please contact admin person', 'Failed!');
      }

    });
  }

  buyNow() {
    this.message = 'Are you sure want to buy this plan ?';
    const dialogRef = this.dialog.open(DeleteDialogComponent, { data: { message: this.message, btn: 'Buy Plan', btn2: 'Cancel' } });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.subscriptionService.buySubcription(this.subscriptionId).subscribe(
          data => {
            if (data) {
              console.log(data);
              const paymentData = {
                hospitalId: data['tblHospital']['hospitalId'],
                doctorId: data['doctor']['id'],
                currentUserLoginId: data['tblUser']['userId'],
                subscriptionId: data['subscription']['id'],
                doctorsubscriptionId: data['id'],
                amount: this.finalPaybelAmout, // this.subscriptionDetails['total'],
              };
              this.paymentService.postPaytmPayment(paymentData).subscribe(
                responseOfPaytm => {
                  console.log(responseOfPaytm);
                  this.paymentService.paytmRedirection(responseOfPaytm);
                }
              );
              /* this.router.navigate(['payment-success/1/1']);
              this.toastr.success('Payment Success!', 'Success!');
              localStorage.setItem('buyedSubscriptionId', null); */
            } else {
              this.router.navigate(['payment-failed/1/1']);
              this.toastr.success('Payment Failed!', 'Failed!');
            }
          }
        );
      }
    });
  }
}
