import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent } from '@angular/common/http';
import { Configuration } from '../app.constants';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    })
};
@Injectable({
    providedIn: 'root'
})
export class CouponCodeService {
    portalAPIUrl = '';
    constructor(private http: HttpClient, private configuration: Configuration) {
        this.portalAPIUrl = configuration.SERVER.portalAPIUrl;
    }
    getAllCouponCodePlan() {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/couponCodeList');
    }

    saveCouponCodePlan(data) {
        return this.http.post(this.portalAPIUrl + '/api/v1/master/saveCouponCode', data, httpOptions).pipe();
    }

    getCouponCodePlanMasterById(couponCodeId) {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/couponCodeById/' + couponCodeId).pipe();
    }

    deleteCouponCodePlanById(couponCodeId) {
        return this.http.get(this.portalAPIUrl + '/api/v1/private/deleteCouponCodePlanById/' + couponCodeId).pipe();
    }
    deactiveCouponCodePlanById(couponCodeId, status) {
        return this.http.get(this.portalAPIUrl + '/api/v1/private/deactiveCouponCodePlanById/' + couponCodeId + '/' + status).pipe();
    }

    saveCouponCodePatient(data) {
        return this.http.post(this.portalAPIUrl + '/api/v1/master/saveCouponCodePatient', data, httpOptions).pipe();
    }
    getAllCouponCodePatientList() {
        return this.http.get(this.portalAPIUrl + '/api/v1/master/couponCodePatientList');
    }

}
