import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerapiService } from '../serverapi.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
  patientType;
  error;
  success = false;
  submitted = false;
  disableSubmitBtn = false;
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private serverAPI: ServerapiService
  ) { }
  registerForm = this.fb.group({
    cancersubsite: [''],
    othercancersubsite: [''],
    treatementopinion: [''],
    stage: [''],
    treatmentHistory: [''],
    firstName: ['', Validators.required],
    middleName: ['', Validators.required],
    lastName: ['', Validators.required],
    gender: ['', Validators.required],
    dateOfBirth: ['', Validators.required],
    age: ['', Validators.required],
    email: [''],
    countryCode: ['+91'],
    mobilenumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)
      , Validators.pattern('^[0-9]{10}$')]],
    country: ['', Validators.required],
    state: ['', Validators.required],
    district: ['', Validators.required],
    city: ['', Validators.required],
    pincode: ['', Validators.required],
    alternativeMobile1: [''],
    alternativeMobile2: [''],
    aadharNo: ['', Validators.required],
    clinic: [''],
    doctorId: ['']
  });
  ngOnInit() {
    this.patientType = this.route.snapshot.paramMap.get('type');
  }
  submit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.disableSubmitBtn = true;
  }

}
