import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { Configuration } from 'src/app/app.constants';
import { ServerapiService } from 'src/app/serverapi.service';
import { SubscriptionService } from 'src/app/subscription/subscription.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LoginComponent } from 'src/app/login/login.component';
import { OncocareDigitalService } from 'src/app/oncocaredigitalservice.service';
import { OncodatabaseserviceService } from 'src/app/oncodatabaseservice.service';
import { LocalStorageService } from 'src/app/login/local-storage.service';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { HttpParams } from '@angular/common/http';
import { Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-header-after-login',
  templateUrl: './header-after-login.component.html',
  styleUrls: ['./header-after-login.component.css']
})
export class HeaderAfterLoginComponent implements OnInit {
  title = 'CLIENT';
  currentUser: number;
  configurations: any;
  loginUserName;
  isSubscription = true;
  userType;
  clsOpen;
  isSubscriptions = false;
  error;
  loginCount;
  registerCount;
  submitted = false;
  granttype = 'access_token';
  loginSucess = false;
  // public loginModel: LoginModel;
  loginForm = this.fb.group({
    username: [localStorage.getItem('portalUserName'), [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    password: [localStorage.getItem('portalPassword'), [Validators.required, Validators.minLength(8)]]
  });
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private fb: FormBuilder,
    private serverapi: ServerapiService,
    private configuration: Configuration,
    private appComponent: AppComponent,
    private subscriptionService: SubscriptionService,
    private oncocareDigitalService: OncocareDigitalService,
    private oncodatabaseserviceService: OncodatabaseserviceService,
    private localStorageService: LocalStorageService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private serverAPI: ServerapiService,

  ) { }

  ngOnInit() {
    this.getSubscription();
    this.currentUser = Number(localStorage.getItem('currentLoingUserId'));
    this.loginUserName = localStorage.getItem('chiefAdmin');
    this.userType = localStorage.getItem('userType');
  }

  logout() {
    this.authenticationService.logout();
    localStorage.clear();
    this.currentUser = 0;
    this.appComponent.logout();
    this.router.navigate(['/home']);
  }


  setLoginValues() {
    this.currentUser = Number(localStorage.getItem('currentLoingUserId'));
    this.loginUserName = localStorage.getItem('chiefAdmin');

  }


  accessApplication(event: any) {
    this.serverapi.getUserFullDetailByName(localStorage.getItem('portalUserName')).subscribe(data => {
      if (data) {
        console.log(data);
        if (event.target.value === 'oncocareDigital') {
          window.location.href = this.configuration.SERVER.oncocareDigitalApplicationUrl + '#/admin';
          // http://localhost:8181/CCI#/admin
        } else if (event.target.value === 'oncocareDatabase') {
          window.location.href = this.configuration.SERVER.oncocareDatabaseApplicationUrl + '#/app/hospital-menu';
          // http://localhost:8181/HIS#/app/hospital-menu
        }
      }
    }, error => console.log(error));
  }

  getSubscription() {
    this.subscriptionService.getSubscription().subscribe(data => {
      if (data) {
        this.isSubscription = false;
      }
    });
  }

  addClass() {
    this.clsOpen = 'open';
  }
  reLogin() {
    localStorage.clear();
    this.login();
  }


  login() {
    this.submitted = true;
    const body = new HttpParams()
      .set('username', this.loginForm.controls.username.value)
      .set('password', this.loginForm.controls.password.value)
      .set('grant_type', 'password');
    localStorage.setItem('userName', this.loginForm.controls.username.value);
    localStorage.setItem('password', this.loginForm.controls.password.value);
    localStorage.setItem('portalUserName', this.loginForm.controls.username.value);
    localStorage.setItem('portalPassword', this.loginForm.controls.password.value);
    const credential = {
      userName: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value,
    };
    this.serverAPI.loginUser(credential).subscribe(data => {
      this.ccilogin();
      if (data != null) {
        this.loginSucess = true;
        this.getRolesAndRights(data[8]);
        localStorage.setItem('hospitalIdPortal', data[0]);
        localStorage.setItem('chiefAdmin', data[1]);
        localStorage.setItem('isSubscription', data[2]);
        localStorage.setItem('firstName', data[3]);
        localStorage.setItem('isSuperAdmin', data[6]);
        localStorage.setItem('isHospitalAdmin', data[7]);
        localStorage.setItem('currentLoingUserId', data[8]);
        localStorage.setItem('doctorId', data[9]);
        localStorage.setItem('userType', data[10]);
        localStorage.setItem('isLogin', 'true');
        this.getPatientCount(data[0]);
        this.getRegisterPatientCount(data[0]);
      }
    },
      error => {
        this.error = 'Username or password is invalid!';
      });
  }

  getRolesAndRights(id) {
    this.serverAPI.subscriptionDetails(id).subscribe(response => {
      localStorage.setItem('CCIRoleRightsPatient', JSON.stringify(response));
    });
  }

  ccilogin() {
    this.oncocareDigitalService.cciLogin(this.loginForm.value).subscribe(
      data => {
        this.hisLogin();
        if (data.status === '400') {
          // this.error = 'Incorrect username/password';
        } else {
          this.loginSucess = true;
          /* this.server.isLoggedIn = true;
          this.server.userType = data.data.userType;
          this.server.role = data.data.role;
          this.server.userName = data.data.name; */
          /* After Login redirection logic */
          let UserType = '';
          if (data.data.userType === 'user') {
            UserType = 'admin';
          } else if (data.data.userType === 'admin') {
            UserType = data.data.userType;
          } else if (data.data.userType === 'patient') {
            UserType = data.data.userType;
            localStorage.setItem('userType', data.data.userType);
          } else if (data.data.userType === 'guest') {
            UserType = 'patient';
          } else if (data.data.userType === 'doctor') {
            UserType = data.data.userType;
          }

          if (data.data.userType === 'doctor') {
            localStorage.setItem('docIdApp', data.data.doctor.id);
          }
          localStorage.setItem('doctorType', data.data.doctorType);
          localStorage.setItem('login', 'true');
          localStorage.setItem('language', 'Gujarati');
          localStorage.setItem('loginuserid', data.data.id);
          localStorage.setItem('registerType', data.data.registerType);
          localStorage.setItem('logindata', JSON.stringify(data.data));
          localStorage.setItem('loginname', data.data.name);
          localStorage.setItem('loginusertype', data.data.userType);
          localStorage.setItem('mobilenumber', data.data.mobilenumber);
          localStorage.setItem('role', data.data.role);
          localStorage.setItem('districtId', data.data.district.id);
          localStorage.setItem('registerType', data.data.registerType);
          if (data.data.regional != null) {
            localStorage.setItem('regionalId', data.data.regional.id);
          }
          localStorage.setItem('userName', data.data.name);
          localStorage.setItem('logindata', JSON.stringify(data.data));
          sessionStorage.setItem('logindataSession', JSON.stringify(data.data));
          // this.router.navigateByUrl('/' + UserType);
          if (data.data.userType === 'user' || data.data.userType === 'patient' || data.data.userType === 'guest') {
            if (data.data.isLoginActive === 1) {
              this.redirectionOnPaymentOrLoginPage();
            } else {
              this.router.navigate(['/patient-payment']);
            }
          } else if (data.data.userType === 'doctor' && data.data.doctorType === 'CCI-Doctor') {
            window.location.href = this.configuration.SERVER.oncocareDigitalApplicationUrl + '#/doctor';
          }
        }
      },
      error => {
        this.error = 'Incorrect username or password';
      }
    );

    // localStorage.setItem("loggedin", "yes");
    // localStorage.setItem("userid", "4");
    // localStorage.setItem("usertype", "doctor");
    // this.router.navigateByUrl('/doctor');
  }

  getPatientCount(hospitalId) {
    this.subscriptionService.getPatientCount(hospitalId).subscribe(data => {
      localStorage.setItem('patientCount', data + ' ');
    });
  }
  getRegisterPatientCount(hospitalId) {
    this.subscriptionService.getRegisterPatientCount(hospitalId).subscribe(data => {
      localStorage.setItem('patientRegsiterCount', data + ' ');
    });
  }


  getregiterCount() {
  }
  getLoginCount() {
  }
  clearErroMsg() {
    this.error = '';
  }
  redirectionOnPaymentOrLoginPage() {
    if (localStorage.getItem('userType') === 'SuperAdmin') {
      this.router.navigate(['/dashboard']);
    } else if (localStorage.getItem('userType') === 'Doctor') {
      this.getSubscriptions();
    } else if (localStorage.getItem('loginusertype') === 'user') {
      window.location.href = this.configuration.SERVER.oncocareDigitalApplicationUrl + '#/admin';
    } else if (localStorage.getItem('loginusertype') === 'patient' || localStorage.getItem('loginusertype') === 'guest') {
      window.location.href = this.configuration.SERVER.oncocareDigitalApplicationUrl + '#/patient';
    } else {
      this.toastr.error('Login Credential Invalid', 'Error!');
    }
  }

  hisLogin() {
    const credential = {
      userName: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value,
    };
    this.oncodatabaseserviceService.hisLogin(credential).subscribe(data => {
      if (data['roles'] === 'AdminDoctor' || data['roles'] === 'admin') {
        this.loginSucess = true;
        this.HISLogin(data);
        localStorage.setItem('patientCount', data + ' ');
      } else if (!this.loginSucess) {
        this.error = 'Incorrect username or password';
        this.toastr.error('Login Credential Invalid', 'Error!');
      }
    },
      error => {
        this.error = 'Incorrect username or password';
      }
    );
  }


  HISLogin(result) {
    console.log(result);
    const auth = result.token;
    this.localStorageService.create();
    this.localStorageService.setValue('userId', result.userId);
    this.localStorageService.setValue('utcOffset', result.utcOffset);
    this.localStorageService.setValue('username', result.username);
    const fullname = this.getUserName(result);
    this.localStorageService.setValue('userfullname', fullname);
    this.localStorageService.setValue('userFLName', this.getUserFLName(result));
    this.localStorageService.setValue('designation', result.designation);
    this.localStorageService.setValue('role', result.roles);
    this.localStorageService.setValue('isBoardMember', result.isBoardMember);
    this.localStorageService.setValue('isSuperAdmin', result.isSuperAdmin);
    this.localStorageService.setValue('isHospitalAdmin', result.isHospitalAdmin);
    this.localStorageService.setValue('hospitalId', result.hospitalId);
    this.localStorageService.setValue('hospitalName', result.hospitalName);
    // this.localStorageService.setValue('accessToken', auth.accessToken);
    this.localStorageService.setValue('accessToken', auth);
    // this.localStorageService.setValue('refreshToken', auth.refreshToken);

    this.localStorageService.setValue('userRoleRightsMapping', JSON.stringify(result.roleRightsMappingList));
    if (result.isSuperAdmin) {
      // this.utilityService.navigateToState(this.configuration.STATES.hospitalMenu);
    } else {
      // this.utilityService.navigateToState(this.configuration.STATES.userDashboard);
    }
    const rights = this.getRightsFromMapping(result.roleRightsMappingList);
    this.localStorageService.setValue('rights', JSON.stringify(rights));
    this.redirectionOnPaymentOrLoginPage();
  }

  getUserName(user) {
    return (user.prefix && user.prefix !== undefined && user.prefix !== null
      && user.prefix !== 'null' ? user.prefix + ' ' : '') + user.firstName + ' ' + user.lastName;
  }
  getUserFLName(user) {
    return user.firstName + ' ' + user.lastName;
  }

  getRightsFromMapping(mapping) {
    const rights = [];
    for (const map of mapping) {
      if (rights.indexOf(map['rightName']) === -1) {
        rights.push(map['rightName']);
      }
    }
    return rights;
  }

  doctorRedirection() {
    if (localStorage.getItem('currentLoingUserId') !== null && localStorage.getItem('buyedSubscriptionId') !== null) {
      this.router.navigate(['/payment/' + localStorage.getItem('currentLoingUserId') + '/' + localStorage.getItem('buyedSubscriptionId')]);
    } else {
      if (this.isSubscriptions === true) {
        window.location.href = this.configuration.SERVER.oncocareDatabaseApplicationUrl + '#/app/user/user-dashboard';
      } else {
        this.router.navigate(['/dashboard']);
      }
    }
  }

  getSubscriptions() {
    this.subscriptionService.getSubscription().subscribe(data => {
      if (data) {
        this.isSubscriptions = true;
      }
      this.doctorRedirection();
    });
  }



}
