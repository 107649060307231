import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DynemicFormService } from '../dynemic-form.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-clinical-details',
  templateUrl: './clinical-details.component.html',
  styleUrls: ['./clinical-details.component.css']
})
export class ClinicalDetailsComponent implements OnInit {
  clinicalDetail;
  submitted;
  primarySiteList;
  subSiteList;
  treatmentList;
  histologicTypeList;
  ptList;
  pnList;
  marginList;
  patientId;
  rxIntentOption = [];
  primarySiteId = '';
  subSiteId = '';
  intentId = '';
  stageTypeTId = '';
  stageTypeNId = '';
  stageTypeMId = '';
  statusId = '';
  treatmentId = '';
  isSelected = true;
  stageTypeTList = [
    { id: 'T1 ', name: 'T1' },
    { id: 'T2', name: 'T2' },
    { id: 'T3', name: 'T3' },
    { id: 'T4a', name: 'T4a' },
    { id: 'T4b', name: 'T4b' },
  ];
  stageTypeNList = [
    { id: 'N1', name: 'N1' },
    { id: 'N2a', name: 'N2a' },
    { id: 'N2b', name: 'N2b' },
    { id: 'N2c', name: 'N2c' },
    { id: 'N3', name: 'N3' },
  ];
  stageTypeMList = [
    { id: 'M0', name: 'M0' },
    { id: 'M1', name: 'M1' },
    { id: 'M2', name: 'M2' },
  ];
  intentList = [
    { id: 'Cure', name: 'Cure' },
    { id: 'Palliation', name: 'Palliation' },
    { id: 'Other', name: 'Other' },
  ];
  primaryResectionList = [
    { id: 'WLE', name: 'WLE' },
    { id: 'Marginal mandible', name: 'Marginal mandible' },
    { id: 'Segmental mandible', name: 'Segmental mandible' },
    { id: 'Mandible + Maxilla', name: 'Mandible + Maxilla' },
    { id: 'Infrastructure maxilla', name: 'Infrastructure maxilla' },
    { id: 'Supra-structure maxilla', name: 'Supra-structure maxilla' },
    { id: 'Total maxilla', name: 'Total maxilla' },
    { id: 'Maxilla + Eye', name: 'Maxilla + Eye' },
    { id: 'CFR', name: 'CFR' },
    { id: 'Composite with ITF', name: 'Composite with ITF' },
    { id: 'Total laryngectomy', name: 'Total laryngectomy' },
    { id: 'Total laryngopharyngectomy', name: 'Total laryngopharyngectomy' },
    { id: 'TPLO', name: 'TPLO' },
    { id: 'Partial laryngeal surgery', name: 'Partial laryngeal surgery' },
    { id: 'Total conservative parotidectomy', name: 'Total conservative parotidectomy' },
    { id: 'Parotidectomy with nerve', name: 'Parotidectomy with nerve' },
    { id: 'Hemithyroidectomy', name: 'Hemithyroidectomy' },
    { id: 'Total thyroidectomy', name: 'Total thyroidectomy' },
    { id: 'Parathyroidectomy', name: 'Parathyroidectomy' },
    { id: 'Tracheal resection', name: 'Tracheal resection' },
    { id: 'MRM', name: 'MRM' },
    { id: 'simple Mastectomy', name: 'simple Mastectomy' },
    { id: 'BCS', name: 'BCS' },
    { id: 'Lobectomy', name: 'Lobectomy' },
    { id: 'Pneumonectomy', name: 'Pneumonectomy' },
    { id: 'Sleeve resection', name: 'Sleeve resection' },
    { id: 'Trans-hiatal esophagectomy', name: 'Trans-hiatal esophagectomy' },
    { id: '3 stage esophagectomy', name: '3 stage esophagectomy' },
    { id: 'Ivor-lewis esophagectomy', name: 'Ivor-lewis esophagectomy' },
    { id: 'Rt hemicolectomy', name: 'Rt hemicolectomy' },
    { id: 'Extended Rt hemicolectomy', name: 'Extended Rt hemicolectomy' },
    { id: 'Lt hemicolectomy', name: 'Lt hemicolectomy' },
    { id: 'Extended lt hemicolectomy', name: 'Extended lt hemicolectomy' },
    { id: 'Sigmoid colectomy', name: 'Sigmoid colectomy' },
    { id: 'Total colectomy', name: 'Total colectomy' },
    { id: 'Anterior resection', name: 'Anterior resection' },
    { id: 'LAR', name: 'LAR' },
    { id: 'Ultra LAR', name: 'Ultra LAR' },
    { id: 'APR', name: 'APR' },
    { id: 'Total proctocolectomy', name: 'Total proctocolectomy' },
    { id: 'ISR', name: 'ISR' },
    { id: 'Total Nephrectomy', name: 'Total Nephrectomy' },
    { id: 'Partial nephrectomy', name: 'Partial nephrectomy' },
    { id: 'Retro-peritoneal mass excision', name: 'Retro-peritoneal mass excision' },
    { id: 'Radical cystectomy', name: 'Radical cystectomy' },
    { id: 'Radical prostatectomy', name: 'Radical prostatectomy' },
    { id: 'Whipple s procedure', name: 'Whipple s procedure' },
    { id: 'Tripple bypass', name: 'Tripple bypass' },
    { id: 'Distal pancreatectomy', name: 'Distal pancreatectomy' },
    { id: 'Enucleation of pancreatic mass', name: 'Enucleation of pancreatic mass' },
    { id: 'Radical cholecystectomy', name: 'Radical cholecystectomy' },
    { id: 'Rt hepatectomy', name: 'Rt hepatectomy' },
    { id: 'Lt hepatectomy', name: 'Lt hepatectomy' },
    { id: 'Segmental hepatectomy', name: 'Segmental hepatectomy' },
    { id: 'G-J', name: 'G-J' },
    { id: 'F-J / F-G', name: 'F-J / F-G' },
    { id: 'Total penectomy', name: 'Total penectomy' },
    { id: 'Partial penectomy', name: 'Partial penectomy' },
    { id: 'Subcapsular orchiectomy', name: 'Subcapsular orchiectomy' },
    { id: 'High inguinal orchiectomy', name: 'High inguinal orchiectomy' },
    { id: 'Soft tissue sarcoma WLE', name: 'Soft tissue sarcoma WLE' },
    { id: 'Amputation', name: 'Amputation' },
    { id: 'Wertheim s hysterectomy', name: 'Wertheim s hysterectomy' },
    { id: 'TAH + BSO', name: 'TAH + BSO' },
    { id: 'TAH + BSO + omentatectomy', name: 'TAH + BSO + omentatectomy' },
    { id: 'Limb sparing surgery', name: 'Limb sparing surgery' },
    { id: 'Other 2', name: 'Other 2' },
    { id: 'other 3', name: 'other 3' },
  ];

  nodeDissectionList = [
    { id: 'SND', name: 'SND' },
    { id: 'MND', name: 'MND' },
    { id: 'RND', name: 'RND' },
    { id: 'Bilateral ND', name: 'Bilateral ND' },
    { id: 'Not done', name: 'Not done' },
    { id: 'Contralateral ND', name: 'Contralateral ND' },
    { id: 'Other', name: 'Other' },
    { id: 'Axillary ND', name: 'Axillary ND' },
    { id: 'Sentinel ND', name: 'Sentinel ND' },
    { id: 'Two field ND', name: 'Two field ND' },
    { id: 'Three field ND', name: 'Three field ND' },
    { id: 'D1 gastrectomy', name: 'D1 gastrectomy' },
    { id: 'D2 gastrectomy', name: 'D2 gastrectomy' },
    { id: 'D3 gastrectomy', name: 'D3 gastrectomy' },
    { id: 'RPLND', name: 'RPLND' },
    { id: 'Pelvic ND', name: 'Pelvic ND' },
    { id: 'Para-aortic ND', name: 'Para-aortic ND' },
    { id: 'Inguinal ND', name: 'Inguinal ND' },
    { id: 'Mediastinal ND', name: 'Mediastinal ND' },
    { id: 'Central compartment ND', name: 'Central compartment ND' },
    { id: 'Salvage ND', name: 'Salvage ND' },
    { id: 'Popliteal ND', name: 'Popliteal ND' },
    { id: 'option22', name: 'option22' },
    { id: 'option23', name: 'option23' },
  ];

  reconstructionList = [
    { id: 'Primary closure', name: 'Primary closure' },
    { id: 'STG', name: 'STG' },
    { id: 'Local Flap', name: 'Local Flap' },
    { id: 'Regional Flap', name: 'Regional Flap' },
    { id: 'Free Flap', name: 'Free Flap' },
    { id: 'Double Flap', name: 'Double Flap' },
    { id: 'Raw area', name: 'Raw area' },
    { id: 'Oncoplastic breast surgery', name: 'Oncoplastic breast surgery' },
    { id: 'P-J', name: 'P-J' },
    { id: 'P-G', name: 'P-G' },
    { id: 'Ileal conduit', name: 'Ileal conduit' },
    { id: 'Neo-bladder', name: 'Neo-bladder' },
    { id: 'Other', name: 'Other' },
  ];

  ENEECSList = [
    { id: 'Negative', name: 'Negative' },
    { id: 'Positive', name: 'Positive' },
  ];

  planofRadiationList = [
    { id: 'Primary', name: 'Primary' },
    { id: 'Adjuvant', name: 'Adjuvant' },
    { id: 'Concurrent', name: 'Concurrent' },
    { id: 'Neo Adjuvant', name: 'Neo Adjuvant' },
    { id: 'Other', name: 'Other' },
  ];

  typeofRadiationList = [
    { id: '2D', name: '2D' },
    { id: '3D', name: '3D' },
    { id: 'IMRT', name: 'IMRT' },
    { id: 'IGRT', name: 'IGRT' },
    { id: 'Branchy Therapy', name: 'Branchy Therapy' },
    { id: 'Other', name: 'Other' },
  ];
  constructor(
    private fb: FormBuilder,
    private dynemicFormService: DynemicFormService,
    private activeRoute: ActivatedRoute,
    private toastr: ToastrService,
    private datePipe: DatePipe
  ) { }


  clinicalDetailForm = this.fb.group({
    primarySite: [''],
    subSite: [''],
    stageTypeT: [''],
    stageTypeN: [''],
    stageTypeM: [''],
    pathology: [''],
    intentMaster: [''],
    treatmentMaster: [''],
    status: ['1'],
    modality: [''],
    dateOfSurgery: [''],
    dateOfDischarge: [''],
    primaryResection: [''],
    nodeDissection: [''],
    reconstruction: [''],
    comment: [''],
    dateOfRTStart: [''],
    dateOfRTComplete: [''],
    fraction: [''],
    does: [''],
    planofRadiation: [''],
    typeofRadiation: [''],
    dateOfAdmission: [''],
    chemoProtocol: [''],
    noOfCycle: [''],
    nextCycle: [''],
    dateOfChemo: [''],
    histologicType: [''],
    pt: [''],
    pn: [''],
    margins: [''],
    ENEECS: [''],
    doctorName: [localStorage.getItem('chiefAdmin')],
    loginUserId: [localStorage.getItem('currentLoingUserId')],
  });


  ngOnInit() {
    this.patientId = this.activeRoute.snapshot.paramMap.get('patientId');
    this.getPrimarySiteListById();
    this.getSubSiteListById();
    this.getTreatmentList();
    this.getHistologicTypeList();
    this.getPTList();
    this.getPNList();
    this.getMarginList();
  }

  saveClinicalForm() {
    this.submitted = true;
    if (this.clinicalDetailForm.invalid) {
      return;
    }
    const clinicalDetail = this.clinicalDetailForm.value;
    clinicalDetail.formFor = 'clinicalDetails';
    clinicalDetail.patientId = this.patientId;
    clinicalDetail.dateOfSurgery = this.datePipe.transform(clinicalDetail.dateOfSurgery, "dd-MMM-yyyy");
    clinicalDetail.dateOfDischarge = this.datePipe.transform(clinicalDetail.dateOfDischarge, "dd-MMM-yyyy");
    clinicalDetail.dateOfRTStart = this.datePipe.transform(clinicalDetail.dateOfRTStart, "dd-MMM-yyyy");
    clinicalDetail.dateOfRTComplete = this.datePipe.transform(clinicalDetail.dateOfRTComplete, "dd-MMM-yyyy");
    clinicalDetail.dateOfChemo = this.datePipe.transform(clinicalDetail.dateOfChemo, "dd-MMM-yyyy");
    clinicalDetail.dateOfAdmission = this.datePipe.transform(clinicalDetail.dateOfAdmission, "dd-MMM-yyyy");
    this.dynemicFormService.saveDynamicForms(clinicalDetail).subscribe(data => {
      this.toastr.success('Clinical Details Update Successfully!', 'Update');
      this.resetForm();
    });
  }

  getMarginList() {
    this.dynemicFormService.getMasterDataByMasterId(13).subscribe(data => {
      this.marginList = data;
    });
  }

  getPTList() {
    this.dynemicFormService.getMasterDataByMasterId(11).subscribe(data => {
      this.ptList = data;
    });
  }

  getPNList() {
    this.dynemicFormService.getMasterDataByMasterId(17).subscribe(data => {
      this.pnList = data;
    });
  }

  getHistologicTypeList() {
    this.dynemicFormService.getMasterDataByMasterId(14).subscribe(data => {
      this.histologicTypeList = data;
    });
  }

  getTreatmentList() {
    this.dynemicFormService.getTreatmentList().subscribe(data => {
      this.treatmentList = data;
    });
  }

  getPrimarySiteListById() {
    this.dynemicFormService.getMasterDataByMasterId(3).subscribe(data => {
      this.primarySiteList = data;
    });
  }

  getSubSiteListById() {
    this.dynemicFormService.getMasterDataByMasterId(4).subscribe(data => {
      this.subSiteList = data;
    });
  }

  resetForm() {
    this.clinicalDetailForm.reset();
    this.primarySiteId = '';
    this.subSiteId = '';
    this.stageTypeTId = '';
    this.stageTypeNId = '';
    this.stageTypeMId = '';
    this.isSelected = true;
  }
}
