import { Component, OnInit } from '@angular/core';
import { ServerapiService } from '../serverapi.service';

@Component({
  selector: 'app-user-summary',
  templateUrl: './user-summary.component.html',
  styleUrls: ['./user-summary.component.css']
})
export class UserSummaryComponent implements OnInit {
  userDetails;

  constructor(private ServerAPI: ServerapiService) { }

  ngOnInit() {
    this.getUserByUserId();
  }

  getUserByUserId() {
    this.ServerAPI.getUserByUserId(localStorage.getItem('currentLoingUserId')).subscribe(
      data => {
        this.userDetails = data;
      });
  }

}
