import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogComponent } from 'src/app/delete-dialog/delete-dialog.component';
import { MasterService } from '../../_services/masterservice.service';

@Component({
  selector: 'app-subsiteemaster',
  templateUrl: './subsiteemaster.component.html',
  styleUrls: ['./subsiteemaster.component.css']
})
export class SubsiteemasterComponent implements OnInit {
  error;
  siteList;
  subSiteList;
  submitted = false;
  disableSubmitBtn = false;
  siteMaster;
  constructor(private toastr: ToastrService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private dialog: MatDialog, private masterService: MasterService) { }
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  siteForm = this.fb.group({
    site: ['', Validators.required],
    subSite: ['', Validators.required]
  });
  ngOnInit() {
    this.getAllSiteList();
    this.getAllSubSiteList();
  }
  getAllSiteList() {
    this.masterService.getAllSiteList().subscribe(data => {
      this.siteList = data;
    });
  }

  getAllSubSiteList() {
    this.masterService.getAllSubSiteList().subscribe(data => {
      this.subSiteList = data;
    });
  }

  submit() {
    this.submitted = true;
    if (this.siteForm.invalid) {
      return;
    }
    this.disableSubmitBtn = true;
    const data = this.siteForm.value;
    data.site = parseInt(data.site, 10);
    if (this.siteMaster) {
      data.id = this.siteMaster.id;
    }
    this.masterService.saveSubSiteMaster(data).subscribe(
      d => {
        this.disableSubmitBtn = false;
        this.getAllSubSiteList();
        this.siteForm.controls.subSite.setValue('');
        this.siteForm.controls.site.setValue('');
        if (this.siteMaster) {
          this.toastr.success('Update Successfully!', 'Update!');
        } else {
          this.toastr.success('Save Successfully!', 'Success!');
        }
        this.siteMaster = '';
        // Fixes # #5873 -- masters -> sub site :- validation message should be displayed at the sub site name if user click on submit without enter nothing.
        this.siteForm.reset();
        this.submitted = false;
      },
      e => {
        this.error = e.error.message;
      }
    );
  }

  getSubSiteMasterById(siteId) {
    this.masterService.getSubSiteMasterById(siteId).subscribe(data => {
      this.siteMaster = data;
      this.siteForm.controls.subSite.setValue(this.siteMaster.subSite);
      this.siteForm.controls.site.setValue(this.siteMaster.site.id);
    });
  }

}
