import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogComponent } from 'src/app/delete-dialog/delete-dialog.component';
import { MasterService } from '../../_services/masterservice.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
class Person {
  id: number;
  siteName: string;
}

@Component({
  selector: 'app-sitemaster',
  templateUrl: './sitemaster.component.html',
  styleUrls: ['./sitemaster.component.css']
})
export class SitemasterComponent implements OnInit {
  error;
  siteList;
  submitted = false;
  disableSubmitBtn = false;
  siteMaster;
  dtOptions: DataTables.Settings = {};
  persons: Person[];
  constructor(private http: HttpClient, private toastr: ToastrService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private dialog: MatDialog, private masterService: MasterService) { }
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  siteForm = this.fb.group({
    siteName: ['', Validators.required]
  });
  ngOnInit() {
    this.getAllSiteList();
    // this.getAllData();
  }
  getAllSiteList() {
    this.masterService.getAllSiteList().subscribe(data => {
      this.siteList = data;
    });
  }

  /*  getAllData() {
     this.dtOptions = {
       pagingType: 'full_numbers',
       pageLength: 10,
       serverSide: true,
       processing: true,
       ajax: (dataTablesParameters: any, callback) => {
         this.http
           .post<DataTablesResponse>(
             'http://localhost:8080/api/v1/master/sitelist',
             dataTablesParameters, {}
           ).subscribe(resp => {
             this.persons = resp.data;
             callback({
               recordsTotal: resp.recordsTotal,
               recordsFiltered: resp.recordsFiltered,
               data: []
             });
           });
       },
       columns: [{ data: 'id' }, { data: 'siteName' }]
     };
   } */

  submit() {
    this.submitted = true;
    if (this.siteForm.invalid) {
      return;
    }
    this.disableSubmitBtn = true;
    const data = this.siteForm.value;
    if (this.siteMaster) {
      data.id = this.siteMaster.id;
    }
    this.masterService.saveSiteMaster(data).subscribe(
      d => {
        this.disableSubmitBtn = false;
        this.getAllSiteList();
        this.siteForm.controls.siteName.setValue('');
        if (this.siteMaster) {
          this.toastr.success('Update Successfully!', 'Update!');
        } else {
          this.toastr.success('Save Successfully!', 'Success!');
        }
        this.siteForm.reset();
        this.submitted = false;
        this.siteMaster = '';
      },
      e => {
        this.error = e.error.message;
      }
    );
  }

 

  getSiteMasterById(siteId) {
    this.masterService.getSiteMasterById(siteId).subscribe(data => {
      this.siteMaster = data;
      this.siteForm.controls.siteName.setValue(this.siteMaster.siteName);
    });
  }

}
