import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OncocareDigitalService } from '../oncocaredigitalservice.service';
import { ServerapiService } from '../serverapi.service';
import { SubscriptionService } from '../subscription/subscription.service';
import { AuthenticationService } from '../_services/authentication.service';
import { LocalStorageService } from './local-storage.service';
import { OncodatabaseserviceService } from '../oncodatabaseservice.service';
import { Configuration } from '../app.constants';
import { ResetPasswordDialogComponent } from '../reset-password-dialog/reset-password-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isSubscription = false;
  error;
  loginCount;
  registerCount;
  submitted = false;
  granttype = 'access_token';
  loginSucess = false;
  // public loginModel: LoginModel;
  loginForm = this.fb.group({
    username: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    password: ['', [Validators.required, Validators.minLength(8)]]
  });
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private serverAPI: ServerapiService,
    private toastr: ToastrService,
    private subscriptionService: SubscriptionService,
    private oncocareDigitalService: OncocareDigitalService,
    private oncodatabaseserviceService: OncodatabaseserviceService,
    private localStorageService: LocalStorageService,
    private configuration: Configuration,
    public dialog: MatDialog,
  ) {
  }
  ngOnInit() {

  }
  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const body = new HttpParams()
      .set('username', this.loginForm.controls.username.value)
      .set('password', this.loginForm.controls.password.value)
      .set('grant_type', 'password');
    localStorage.setItem('userName', this.loginForm.controls.username.value);
    localStorage.setItem('password', this.loginForm.controls.password.value);
    localStorage.setItem('portalUserName', this.loginForm.controls.username.value);
    localStorage.setItem('portalPassword', this.loginForm.controls.password.value);
    const credential = {
      userName: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value,
    };
    this.serverAPI.loginUser(credential).subscribe(data => {
      this.ccilogin();
      if (data != null) {
        this.loginSucess = true;
        this.getRolesAndRights(data[8]);
        localStorage.setItem('extPatient', 'false');
        localStorage.setItem('hospitalIdPortal', data[0]);
        localStorage.setItem('chiefAdmin', data[1]);
        localStorage.setItem('isSubscription', data[2]);
        localStorage.setItem('firstName', data[3]);
        localStorage.setItem('isSuperAdmin', data[6]);
        localStorage.setItem('isHospitalAdmin', data[7]);
        localStorage.setItem('currentLoingUserId', data[8]);
        localStorage.setItem('doctorId', data[9]);
        localStorage.setItem('userType', data[10]);
        localStorage.setItem('isLogin', 'true');
        this.getPatientCount(data[0]);
        this.getRegisterPatientCount(data[0]);
      }
    },
      error => {
        this.error = 'Username or password is invalid!';
      });
  }

  getRolesAndRights(id) {
    this.serverAPI.subscriptionDetails(id).subscribe(response => {
      localStorage.setItem('CCIRoleRightsPatient', JSON.stringify(response));
    });
  }

  ccilogin() {
    this.oncocareDigitalService.cciLogin(this.loginForm.value).subscribe(
      data => {
        this.hisLogin();
        if (data.status === '400') {
          // this.error = 'Incorrect username/password';
        } else {
          this.loginSucess = true;
          /* this.server.isLoggedIn = true;
          this.server.userType = data.data.userType;
          this.server.role = data.data.role;
          this.server.userName = data.data.name; */
          /* After Login redirection logic */
          let UserType = '';
          if (data.data.userType === 'user') {
            UserType = 'admin';
          } else if (data.data.userType === 'admin') {
            UserType = data.data.userType;
          } else if (data.data.userType === 'patient') {
            UserType = data.data.userType;
            localStorage.setItem('userType', data.data.userType);
          } else if (data.data.userType === 'guest') {
            UserType = 'patient';
          } else if (data.data.userType === 'doctor') {
            UserType = data.data.userType;
          }

          if (data.data.userType === 'doctor') {
            localStorage.setItem('docIdApp', data.data.doctor.id);
            localStorage.setItem('CCIDoctorId', data.data.doctor.id);
          }
          localStorage.setItem('doctorType', data.data.registerType);
          localStorage.setItem('login', 'true');
          localStorage.setItem('language', 'Gujarati');
          localStorage.setItem('loginuserid', data.data.id);
          localStorage.setItem('registerType', data.data.registerType);
          localStorage.setItem('logindata', JSON.stringify(data.data));
          localStorage.setItem('loginname', data.data.name);
          localStorage.setItem('loginusertype', data.data.userType);
          localStorage.setItem('mobilenumber', data.data.mobilenumber);
          localStorage.setItem('role', data.data.role);
          localStorage.setItem('districtId', data.data.district.id);
          localStorage.setItem('registerType', data.data.registerType);
          localStorage.setItem('patientSize', data.patientSize);
          if (data.data.regional != null) {
            localStorage.setItem('regionalId', data.data.regional.id);
          }
          localStorage.setItem('userName', data.data.name);
          localStorage.setItem('logindata', JSON.stringify(data.data));
          sessionStorage.setItem('logindataSession', JSON.stringify(data.data));
          // this.router.navigateByUrl('/' + UserType);
          if (data.data.userType === 'user' || data.data.userType === 'patient' || data.data.userType === 'guest') {
            if (data.data.isLoginActive === 1) {
              this.redirectionOnPaymentOrLoginPage();
            } else {
              this.router.navigate(['/patient-payment']);
            }
          } else if (data.data.userType === 'doctor' && data.data.registerType === 'CCI-Doctor') {
            window.location.href = this.configuration.SERVER.oncocareDigitalApplicationUrl + '#/doctor';
          }
        }
      },
      error => {
        this.error = 'Incorrect username or password';
      }
    );

    // localStorage.setItem("loggedin", "yes");
    // localStorage.setItem("userid", "4");
    // localStorage.setItem("usertype", "doctor");
    // this.router.navigateByUrl('/doctor');
  }

  getPatientCount(hospitalId) {
    this.subscriptionService.getPatientCount(hospitalId).subscribe(data => {
      localStorage.setItem('patientCount', data + ' ');
    });
  }
  getRegisterPatientCount(hospitalId) {
    this.subscriptionService.getRegisterPatientCount(hospitalId).subscribe(data => {
      localStorage.setItem('patientRegsiterCount', data + ' ');
    });
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  getregiterCount() {
  }
  getLoginCount() {
  }
  clearErroMsg() {
    this.error = '';
  }
  redirectionOnPaymentOrLoginPage() {
    if (localStorage.getItem('userType') === 'SuperAdmin') {
      this.router.navigate(['/dashboard']);
    } else if (localStorage.getItem('userType') === 'Doctor') {
      this.getSubscription();
    } else if (localStorage.getItem('loginusertype') === 'user') {
      window.location.href = this.configuration.SERVER.oncocareDigitalApplicationUrl + '#/admin';
    } else if (localStorage.getItem('loginusertype') === 'patient' || localStorage.getItem('loginusertype') === 'guest') {
      if (localStorage.getItem('patientSize')) {
        if (Number(localStorage.getItem('patientSize')) > 1) {
          window.location.href = this.configuration.SERVER.oncocareDigitalApplicationUrl + '#/patient_login_list';
        } else {
          window.location.href = this.configuration.SERVER.oncocareDigitalApplicationUrl + '#/patient';
        }
      } else {
        window.location.href = this.configuration.SERVER.oncocareDigitalApplicationUrl + '#/patient';
      }
    } else {
      this.toastr.error('Login Credential Invalid', 'Error!');
    }
  }

  hisLogin() {
    const credential = {
      userName: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value,
    };
    this.oncodatabaseserviceService.hisLogin(credential).subscribe(data => {
      if (data['roles'] === 'AdminDoctor' || data['roles'] === 'admin') {
        this.loginSucess = true;
        this.HISLogin(data);
        localStorage.setItem('patientCount', data + ' ');
      } else if (!this.loginSucess) {
        this.error = 'Incorrect username or password';
        this.toastr.error('Login Credential Invalid', 'Error!');
      }
    },
      error => {
        this.error = 'Incorrect username or password';
      }
    );
  }


  HISLogin(result) {
    console.log(result);
    const auth = result.token;
    this.localStorageService.create();
    this.localStorageService.setValue('userId', result.userId);
    this.localStorageService.setValue('utcOffset', result.utcOffset);
    this.localStorageService.setValue('username', result.username);
    const fullname = this.getUserName(result);
    this.localStorageService.setValue('userfullname', fullname);
    this.localStorageService.setValue('userFLName', this.getUserFLName(result));
    this.localStorageService.setValue('designation', result.designation);
    this.localStorageService.setValue('role', result.roles);
    this.localStorageService.setValue('isBoardMember', result.isBoardMember);
    this.localStorageService.setValue('isSuperAdmin', result.isSuperAdmin);
    this.localStorageService.setValue('isHospitalAdmin', result.isHospitalAdmin);
    this.localStorageService.setValue('hospitalId', result.hospitalId);
    this.localStorageService.setValue('hospitalName', result.hospitalName);
    // this.localStorageService.setValue('accessToken', auth.accessToken);
    this.localStorageService.setValue('accessToken', auth);
    // this.localStorageService.setValue('refreshToken', auth.refreshToken);

    this.localStorageService.setValue('userRoleRightsMapping', JSON.stringify(result.roleRightsMappingList));
    if (result.isSuperAdmin) {
      // this.utilityService.navigateToState(this.configuration.STATES.hospitalMenu);
    } else {
      // this.utilityService.navigateToState(this.configuration.STATES.userDashboard);
    }
    const rights = this.getRightsFromMapping(result.roleRightsMappingList);
    this.localStorageService.setValue('rights', JSON.stringify(rights));
    this.redirectionOnPaymentOrLoginPage();
  }

  getUserName(user) {
    return (user.prefix && user.prefix !== undefined && user.prefix !== null
      && user.prefix !== 'null' ? user.prefix + ' ' : '') + user.firstName + ' ' + user.lastName;
  }
  getUserFLName(user) {
    return user.firstName + ' ' + user.lastName;
  }

  getRightsFromMapping(mapping) {
    const rights = [];
    for (const map of mapping) {
      if (rights.indexOf(map['rightName']) === -1) {
        rights.push(map['rightName']);
      }
    }
    return rights;
  }

  getSubscription() {
    console.log('hi.................');
    this.subscriptionService.getSubscription().subscribe(data => {
      if (data) {
        console.log(data);
        this.isSubscription = true;
      }
      this.doctorRedirection();
    });
  }

  doctorRedirection() {
    if (localStorage.getItem('currentLoingUserId') !== null && localStorage.getItem('buyedSubscriptionId') !== null) {
      this.router.navigate(['/payment/' + localStorage.getItem('currentLoingUserId') + '/' + localStorage.getItem('buyedSubscriptionId')]);
    } else {
      if (this.isSubscription === true) {
        window.location.href = this.configuration.SERVER.oncocareDatabaseApplicationUrl + '#/app/user/user-dashboard';
      } else {
        this.router.navigate(['/dashboard']);
      }
    }
  }

  forgotPassword(): void {
    const dialogRef = this.dialog.open(ResetPasswordDialogComponent, {
      width: '550px',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

}
export class LoginModel {
  constructor(
    public username: string, // required
    public password: string, // required, must be 8-12 characters,
    public grantType: string // either 'password' or 'accessToken'
  ) { }
}
