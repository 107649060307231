import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { ServerapiService } from '../serverapi.service';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.css']
})
export class ResetPasswordDialogComponent implements OnInit {

  resetdialogform = this.fb.group({
    mobilenumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[2-9]{2}[0-9]{8}$')]],
    verifyotp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]{6}$')]]
  });
  flagSendOtp = false;
  personId: string;
  constructor(
    public dialogRef: MatDialogRef<ResetPasswordDialogComponent>,
    private fb: FormBuilder,
    private serverapiService: ServerapiService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
  }

  sendOtp() {
    if (this.resetdialogform.controls.mobilenumber.value && this.resetdialogform.controls.mobilenumber.value.length >= 10) {
      if (this.resetdialogform.controls.mobilenumber.invalid) {
        this.resetdialogform.get('mobilenumber').markAsTouched();
        return;
      }
      this.serverapiService.sendOtp(this.resetdialogform.controls.mobilenumber.value).subscribe(
        data => {
          if (data.status === '200') {
            alert('Invalid Mobile Number!');
            this.resetdialogform.controls.mobilenumber.setValue('');
          }
          if (data.status === '400') {
            alert('Otp sent to ' + this.resetdialogform.controls.mobilenumber.value + 'successfully!');
            this.personId = data.data.id;
            this.flagSendOtp = true;

          }
        },
        e => {
          //        this.error = e.error.message;
        }
      );
    }
  }

  verifyOtp() {
    if (this.resetdialogform.controls.verifyotp.value && this.resetdialogform.controls.verifyotp.value.length >= 6) {
      if (this.resetdialogform.controls.verifyotp.invalid) {
        this.resetdialogform.get('verifyotp').markAsTouched();
        return;
      }
      this.serverapiService.verifyOtp(this.resetdialogform.controls.verifyotp.value, this.resetdialogform.controls.mobilenumber.value).subscribe(
        data => {
          if (data.status === '400') {
            alert('Invalid Otp Number!');
            this.resetdialogform.controls.verifyotp.setValue('');
          }
          if (data.status === '200') {
            alert('Otp verified suceessfully');
            this.dialogRef.close();
            this.forgotPassword(this.resetdialogform.controls.verifyotp.value, this.resetdialogform.controls.mobilenumber.value);
            this.flagSendOtp = true;
          }
        },
        e => {
          //        this.error = e.error.message;
        }
      );
    }
  }

  forgotPassword(otp, mobilenumber): void {
    const dialogRef = this.dialog.open(ForgetPasswordComponent, {
      width: '550px',
      height: '550px',
      data: { otp, mobilenumber },
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

}
