import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from '../payment/payment.service';
import { ServerapiService } from '../serverapi.service';
import { SubscriptionService } from '../subscription/subscription.service';

@Component({
  selector: 'app-payment-failed',
  templateUrl: './payment-failed.component.html',
  styleUrls: ['./payment-failed.component.css']
})
export class PaymentFailedComponent implements OnInit {
  paymentId;
  subscriptionId;
  paymentResponseDetails;
  userDetails;
  subscriptionDetails;

  constructor(
    private paymentService: PaymentService,
    private activeRoute: ActivatedRoute,
    private ServerAPI: ServerapiService,
    private subscriptionService: SubscriptionService,
  ) { }

  ngOnInit() {
    this.paymentId = this.activeRoute.snapshot.paramMap.get('paymentId');
    this.subscriptionId = this.activeRoute.snapshot.paramMap.get('subscriptionId');
    this.getPaytmResponseByPaytmResponseId();
    this.getUserByUserId();
    this.getSubscriptionMasterById();
  }


  getPaytmResponseByPaytmResponseId = () => {
    this.paymentService.getPaytmResponseByPaytmResponseId(this.paymentId).subscribe(
      data => {
        this.paymentResponseDetails = data;
      }
    );
  }

  getUserByUserId() {
    this.ServerAPI.getUserByUserId(localStorage.getItem('currentLoingUserId')).subscribe(
      data => {
        this.userDetails = data;
      });
  }

  getSubscriptionMasterById() {
    this.subscriptionService.getSubscriptionMasterById(this.subscriptionId).subscribe(data => {
      this.subscriptionDetails = data;
    });
  }
}
