import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from '../../_services/masterservice.service';

@Component({
  selector: 'app-lookingformaster',
  templateUrl: './lookingformaster.component.html',
  styleUrls: ['./lookingformaster.component.css']
})
export class LookingformasterComponent implements OnInit {
  error;
  lookingForList;
  submitted = false;
  disableSubmitBtn = false;
  lookingForMaster;
  constructor(private toastr: ToastrService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private dialog: MatDialog, private masterService: MasterService) { }
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  lookingForForm = this.fb.group({
    lookingFor: ['', Validators.required]
  });
  ngOnInit() {
    this.getAllLookingForList();
  }
  getAllLookingForList() {
    this.masterService.getAllLookingForList().subscribe(data => {
      this.lookingForList = data;
    });
  }
// Fixes #5880 --  masters -> Looking for :- validation should not be displayed at below field after insert or edit operation.
  submit() {
    this.submitted = true;
    if (this.lookingForForm.invalid) {
      return;
    }
    this.disableSubmitBtn = true;
    const data = this.lookingForForm.value;
    if (this.lookingForMaster) {
      data.id = this.lookingForMaster.id;
    }
    this.masterService.saveLookingForMaster(data).subscribe(
      d => {
        this.disableSubmitBtn = false;
        this.getAllLookingForList();
        this.lookingForForm.controls.lookingFor.setValue('');        
        if (this.lookingForMaster) {
          this.toastr.success('Update Successfully!', 'Update!');
        } else {
          this.toastr.success('Save Successfully!', 'Success!');
        }
        this.lookingForMaster = '';
        this.lookingForForm.reset();
        this.submitted = false;
      },
      e => {
        this.error = e.error.message;
      }
    );
  }

  getLookingForMasterById(siteId) {
    this.masterService.getLookingForMasterById(siteId).subscribe(data => {
      this.lookingForMaster = data;
      this.lookingForForm.controls.lookingFor.setValue(this.lookingForMaster.lookingFor);
    });
  }
}
