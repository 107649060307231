import { Component, OnInit } from '@angular/core';
import { SubscriptionService } from '../subscription/subscription.service';

@Component({
  selector: 'app-my-subscription',
  templateUrl: './my-subscription.component.html',
  styleUrls: ['./my-subscription.component.css']
})
export class MySubscriptionComponent implements OnInit {
  subscriptionList;
  totalSubscriptionCount  = localStorage.getItem('patientCount');
  totalPatientCount  = localStorage.getItem('patientRegsiterCount');

  constructor(
    private subscriptionService: SubscriptionService,
  ) { }

  ngOnInit() {
    this.getSubscription();
  }
  getSubscription() {
    this.subscriptionService.getMySubscriptionByHospitalId(localStorage.getItem('hospitalIdPortal')).subscribe(data => {
      this.subscriptionList = data;
    });
  }

}
