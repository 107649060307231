import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancer-self-help-website',
  templateUrl: './cancer-self-help-website.component.html',
  styleUrls: ['./cancer-self-help-website.component.css']
})
export class CancerSelfHelpWebsiteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
  }

}
