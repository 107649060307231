import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogComponent } from 'src/app/delete-dialog/delete-dialog.component';
import { MasterService } from '../../_services/masterservice.service';

@Component({
  selector: 'app-subtypemaster',
  templateUrl: './subtypemaster.component.html',
  styleUrls: ['./subtypemaster.component.css']
})
export class SubtypemasterComponent implements OnInit {
  error;
  subTypeList;
  submitted = false;
  disableSubmitBtn = false;
  subTypeMaster;
  constructor(private toastr: ToastrService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private dialog: MatDialog, private masterService: MasterService) { }
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  subTypeForm = this.fb.group({
    subType: ['', Validators.required]
  });
  ngOnInit() {
    this.getAllSubTypeList();
  }
  getAllSubTypeList() {
    this.masterService.getAllSubTypeList().subscribe(data => {
      this.subTypeList = data;
    });
  }

  submit() {
    this.submitted = true;
    if (this.subTypeForm.invalid) {
      return;
    }
    this.disableSubmitBtn = true;
    const data = this.subTypeForm.value;
    if (this.subTypeMaster) {
      data.id = this.subTypeMaster.id;
    }
    this.masterService.saveSubTypeMaster(data).subscribe(
      d => {
        this.disableSubmitBtn = false;
        this.getAllSubTypeList();
        this.subTypeForm.controls.subType.setValue('');
        if (this.subTypeMaster) {
          this.toastr.success('Update Successfully!', 'Update!');
        } else {
          this.toastr.success('Save Successfully!', 'Success!');
        }
        this.subTypeMaster = '';
        this.subTypeForm.reset();
        this.submitted = false;
      },
      e => {
        this.error = e.error.message;
      }
    );
  }

  getSubTypeMasterById(typeId) {
    this.masterService.getSubTypeMasterById(typeId).subscribe(data => {
      this.subTypeMaster = data;
      this.subTypeForm.controls.subType.setValue(this.subTypeMaster.subType);
    });
  }
}
