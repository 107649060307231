import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppComponent } from '../app.component';
import { ServerapiService } from '../serverapi.service';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-chahge-password',
  templateUrl: './chahge-password.component.html',
  styleUrls: ['./chahge-password.component.css']
})
export class ChahgePasswordComponent implements OnInit {

  changePasswordForm = this.formBuilder.group({
    oldpassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
    newpassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
    confirmpassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(15)]],
    userName: [''],
  });
  /* Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@\[-`{-~]).{8,15}$') */

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private serverapiService: ServerapiService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService,
    private appComponent: AppComponent,
  ) { }

  ngOnInit() {
    this.changePasswordForm.controls.userName.setValue(localStorage.getItem('portalUserName'));
  }

  onSubmit() {
    const datas = this.changePasswordForm.value;
    this.serverapiService.changePassword(datas).subscribe(
      data => {
        if (data.status === '200') {
          this.logout();
          this.toastr.success('Password Change Successfully!', 'Success!');
        } else {
          this.toastr.error(data.message, 'Error!');
        }
      },
      error => {
      }
    );
  }
  logout() {
    this.authenticationService.logout();
    localStorage.clear();
    this.appComponent.logout();
    this.router.navigate(['/home']);
  }
}
