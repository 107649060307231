import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ServerapiService } from 'src/app/serverapi.service';
import { ToastrService } from 'ngx-toastr';
import { OncodatabaseserviceService } from 'src/app/oncodatabaseservice.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  submitted = false;
  countryId = '';
  countryList;
  stateId = '';
  stateList;
  districtId = '';
  districtList;
  lookingFor;
  constructor(private fb: FormBuilder, private serverAPI: ServerapiService, private toastr: ToastrService, private oncodbService: OncodatabaseserviceService) {
    this.lookingFor = [
      { id: 'Need support for cancer patient', name: 'Need support for cancer patient' },
      { id: 'Awareness campaign or screening program', name: 'Awareness campaign or screening program' },
      { id: 'Establishing this program in any cancer clinic / institute', name: 'Establishing this program in any cancer clinic / institute' },
      { id: 'NGO – to participate in our community program', name: 'NGO – to participate in our community program' },
      { id: 'As a medical doctor- want to train for our community oncology program', name: 'As a medical doctor- want to train for our community oncology program' },
      { id: 'Wish to donate to support our charity drive', name: 'Wish to donate to support our charity drive' },
      { id: 'Others', name: 'Others' },
    ];
  }
  patientRegistrationForm = this.fb.group({
    name: ['', Validators.required],
    country: ['101', Validators.required],
    state: ['', Validators.required],
    district: ['', Validators.required],
    city: ['', Validators.required],
    lookingFor: ['', Validators.required],
    emailId: ['', [Validators.required, Validators.email]],
    description: ['', Validators.required],
    mobileNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]{10}$')]],
  });
  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.getAllCountries();
  }

  getAllCountries() {
    this.serverAPI.getCountries().subscribe(
      data => {
        this.countryList = data;
        this.patientRegistrationForm.controls.country.setValue('101');
        this.getStateByCountryId();
      });
  }

  getStateByCountryId() {
    this.serverAPI.getStates(this.patientRegistrationForm.controls.country.value).subscribe(
      data => {
        this.stateList = data;
        this.patientRegistrationForm.controls.state.setValue('12');
        this.getDistrictByStateId();
      });
  }

  getDistrictByStateId() {
    this.serverAPI.getDistricts(this.patientRegistrationForm.controls.state.value).subscribe(
      data => {
        this.districtList = data;
      });
  }

  submit() {
    this.submitted = true;
    if (this.patientRegistrationForm.invalid) {
      return;
    }
    const data = this.patientRegistrationForm.value;
    this.oncodbService.saveContactForm(data).subscribe(
      response => {
        this.toastr.success('Form saved successfully!', 'Contact Us');
        this.patientRegistrationForm.reset();
        this.submitted = false;
      });
  }
}
