import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cancer-awareness-talks',
  templateUrl: './cancer-awareness-talks.component.html',
  styleUrls: ['./cancer-awareness-talks.component.css']
})
export class CancerAwarenessTalksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
  }

}
