import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionService } from '../subscription/subscription.service';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
  subscriptionList;
  isSubscription = true;
  currentLoingUserId = 0;
  constructor(private router: Router, private subscriptionService: SubscriptionService, private authenticationService: AuthenticationService) {
  }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.getSubscription();
    this.getAllSubscriptionPlan();
    this.currentLoingUserId =  Number(localStorage.getItem('currentLoingUserId'));
  }
  getAllSubscriptionPlan() {
    if (this.isSubscription) {
      this.subscriptionService.getAllSubscriptionPlanForHospital().subscribe(data => {
        this.subscriptionList = data;
      });
    }
  }
  getSubscription() {
    this.subscriptionService.getSubscription().subscribe(data => {
      if (data) {
        this.isSubscription = false;
      }
    });
  }

  redirectionOnPaymentOrLoginPage(subscriptionId) {
    localStorage.setItem('buyedSubscriptionId', subscriptionId);
    if (localStorage.getItem('currentLoingUserId') !== null) {
      this.router.navigate(['/payment/' + localStorage.getItem('currentLoingUserId') + '/' + subscriptionId]);
    } else {
      this.router.navigate(['/login-signup/' + subscriptionId]);
    }
  }

  buyNow(subscriptionId) {
    this.subscriptionService.buySubcription(subscriptionId).subscribe(
      data => {
        this.isSubscription = true;
        if (data === true) {
          this.router.navigate(['/dashboard']);
        }
      });
  }
}
