import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-government-and-regional-authorities',
  templateUrl: './government-and-regional-authorities.component.html',
  styleUrls: ['./government-and-regional-authorities.component.css']
})
export class GovernmentAndRegionalAuthoritiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
  }

}
