import { Component, OnInit } from '@angular/core';
import { ServerapiService } from '../serverapi.service';

@Component({
  selector: 'app-patient-summary',
  templateUrl: './patient-summary.component.html',
  styleUrls: ['./patient-summary.component.css']
})
export class PatientSummaryComponent implements OnInit {
  patientDetails;
  payableAmount;
  constructor(
    private ServerAPI: ServerapiService
  ) { }

  ngOnInit() {
    this.getPatientByUserId();
  }

  getPatientByUserId() {
    this.ServerAPI.getPatientSummaryByUserId(localStorage.getItem('currentLoingUserId')).subscribe(
      data => {
        this.patientDetails = data[0];
        this.payableAmount = data[0][4];
      });
  }

}
