import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-possibility-to-add-more-in-future',
  templateUrl: './possibility-to-add-more-in-future.component.html',
  styleUrls: ['./possibility-to-add-more-in-future.component.css']
})
export class PossibilityToAddMoreInFutureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
  }

}
