import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drugs-and-prosthesis',
  templateUrl: './drugs-and-prosthesis.component.html',
  styleUrls: ['./drugs-and-prosthesis.component.css']
})
export class DrugsAndProsthesisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
  }

}
