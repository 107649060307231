import { Component, OnInit } from '@angular/core';
import { Configuration } from 'src/app/app.constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  currentUser = 0;
  databaseRedirectUrl;
  CCIRedirectUrl;


  constructor(private configuration: Configuration) {
    this.databaseRedirectUrl =   this.configuration.SERVER.databaseRedirectUrl;
    this.CCIRedirectUrl =   this.configuration.SERVER.CCIRedirectUrl;
   }

  ngOnInit() {
  }

}
