import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { SubscriptionService } from '../subscription/subscription.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  isAdmin = false;
  isHospitalAdmin = false;
  isSubscription = true;
  constructor(
    private subscriptionService: SubscriptionService,
    private myCom: AppComponent,
  ) { }

  ngOnInit() {
    this.myCom.setLoginValues();
    if (localStorage.getItem('isSuperAdmin') === '1') {
      this.isAdmin = true;
    }
    if (localStorage.getItem('isHospitalAdmin') === '1' && localStorage.getItem('isSuperAdmin') !== '1') {
      this.isHospitalAdmin = true;
    }
    this.getSubscription();
  }
  getSubscription() {
    this.subscriptionService.getSubscription().subscribe(data => {
      if (data) {
        this.isSubscription = false;
      }
    });
  }


}
