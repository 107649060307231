import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogComponent } from 'src/app/delete-dialog/delete-dialog.component';
import { MasterService } from '../../_services/masterservice.service';

@Component({
  selector: 'app-stagemaster',
  templateUrl: './stagemaster.component.html',
  styleUrls: ['./stagemaster.component.css']
})
export class StagemasterComponent implements OnInit {
  error;
  stageList;
  submitted = false;
  disableSubmitBtn = false;
  stageMaster;
  constructor(private toastr: ToastrService, private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private dialog: MatDialog, private masterService: MasterService) { }
  @ViewChild('inputFile', { static: false }) myInputVariable: ElementRef;
  stageForm = this.fb.group({
    stageName: ['', Validators.required]
  });
  ngOnInit() {
    this.getAllStageList();
  }
  getAllStageList() {
    this.masterService.getAllStageList().subscribe(data => {
      this.stageList = data;
    });
  }

  submit() {
    this.submitted = true;
    if (this.stageForm.invalid) {
      return;
    }
    this.disableSubmitBtn = true;
    const data = this.stageForm.value;
    if (this.stageMaster) {
      data.id = this.stageMaster.id;
    }
    this.masterService.saveStageMaster(data).subscribe(
      d => {
        this.disableSubmitBtn = false;
        this.getAllStageList();
        this.stageForm.controls.stageName.setValue('');
        if (this.stageMaster) {
          this.toastr.success('Update Successfully!', 'Update!');
        } else {
          this.toastr.success('Save Successfully!', 'Success!');
        }
        this.stageMaster = '';
        this.stageForm.reset();
        this.submitted = false;
      },
      e => {
        this.error = e.error.message;
      }
    );
  }

  getStageMasterById(stageId) {
    this.masterService.getStageMasterById(stageId).subscribe(data => {
      this.stageMaster = data;
      this.stageForm.controls.stageName.setValue(this.stageMaster.stageName);
    });
  }
}
