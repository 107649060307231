import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Configuration } from './app.constants';
import { ServerapiService } from './serverapi.service';
import { AuthenticationService } from './_services/authentication.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CLIENT';
  currentUser: number;
  configurations: any;
  loginUserName;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private serverapi: ServerapiService,
    private configuration: Configuration
  ) {
    this.currentUser = Number(localStorage.getItem('currentLoingUserId'));
  }

  headerForm = this.fb.group({
    patientService: ['']
  });

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.currentUser = Number(localStorage.getItem('currentLoingUserId'));
  }

  setLoginValues() {
    this.currentUser = Number(localStorage.getItem('currentLoingUserId'));
    this.loginUserName = localStorage.getItem('chiefAdmin');

  }

  logout() {
    this.authenticationService.logout();
    localStorage.clear();
    this.currentUser = 0;
  }
  accessApplication(event: any) {
    this.serverapi.getUserFullDetailByName(localStorage.getItem('portalUserName')).subscribe(data => {
      if (data) {
        console.log(data);
        if (event.target.value === 'oncocareDigital') {
          window.location.href = this.configuration.SERVER.oncocareDigitalApplicationUrl + '#/doctor';
        } else if (event.target.value === 'oncocareDatabase') {
          window.location.href = this.configuration.SERVER.oncocareDatabaseApplicationUrl + '#/app/user/user-dashboard';
        }
      }
    }, error => console.log(error));
  }
}



