import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '../app.constants';



const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class DynemicFormService {
  private portalUrl = '';
  constructor(private http: HttpClient, private configuration: Configuration) {
    this.portalUrl = configuration.SERVER.portalAPIUrl;
  }

  getMasterDataByMasterId(masterId) {
    return this.http.get(this.portalUrl + '/api/v1/patient/getMasterDataById/' + masterId);
  }

  getTreatmentList() {
    return this.http.get(this.portalUrl + '/api/v1/patient/getTreatmentList');
  }

  saveDynamicForms(data) {
    return this.http.post(this.portalUrl + '/api/v1/saveDynamicForms', data, httpOptions).pipe();
  }
}
