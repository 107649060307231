import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { OncocareDigitalService } from '../oncocaredigitalservice.service';

@Component({
  selector: 'app-add-edit-functional-outcome',
  templateUrl: './add-edit-functional-outcome.component.html',
  styleUrls: ['./add-edit-functional-outcome.component.css']
})
export class AddEditFunctionalOutcomeComponent implements OnInit {
  treatmentMasterList;
  currentIssueMasterList;
  functionalOutcomeFormList;
  functionalOutComeCategoryList;
  submitted = false;
  functionalFormForPatientList: FunctionalFormForPatient[] = [];
  selectedtreatmentId = '';
  selectedcurrentIssueId = '';
  patientId;
  functionalOutcomePatientList;
  hideShowQuestionAnswer = false;
  disableSubmitBtn = false;
  parentRedirectionURL;
  totalAttempetd: number[] = [];
  lastAttemptedData;
  userType;
  answer = 'answer';
  personId;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private oncocareDigitalService: OncocareDigitalService,
  ) { }

  functionalOutcomePatient = this.fb.group({
    treatmentMaster: ['', Validators.required],
    currentIssueMaster: ['', Validators.required],
    functionalOutComeForm: ['', Validators.required],
    functionalOutComeCategory: ['', Validators.required],
  });
  ngOnInit() {
    this.personId = this.data.personId;
    this.patientId = this.data.patientId;
    this.getAllTreatmentMaster();
    this.getAllCurrentIssueMaster();
    this.getFunctionalOutcomePatientById();
    this.getFunctionalFormForPatientByIdGroupby();
    this.getAllFunctionalOutComeCategory();
    this.disableSubmitBtn = false;
    this.parentRedirectionURL = localStorage.getItem('parentRedirectionURL');
  }
  getAllFunctionalOutComeCategory() {
    this.oncocareDigitalService.getAllFunctionalOutComeCategory().subscribe(data => {
      this.functionalOutComeCategoryList = data;
    });
  }
  getAllTreatmentMaster() {
    this.oncocareDigitalService.getAllTreatmentMaster().subscribe(data => {
      this.treatmentMasterList = data;
    });
  }
  getFunctionOutComeFormByCategoryId() {
    this.getAllFunctionalOutComeForm(this.functionalOutcomePatient.value.functionalOutComeCategory);
  }
  getAllCurrentIssueMaster() {
    this.oncocareDigitalService.getAllCurrentIssueMaster().subscribe(data => {
      this.currentIssueMasterList = data;
    });
  }

  getAllFunctionalOutComeForm(categoryId) {
    this.oncocareDigitalService.getAllFunctionalOutComeFormByCategory(categoryId).subscribe(data => {
      this.functionalOutcomeFormList = data;
    });
  }

  onChange(questionId: string, answerId: string) {
    let count = 0;
    this.functionalFormForPatientList.forEach(element => {
      if (element.questionId === questionId) {
        this.functionalFormForPatientList.splice(count, 1);
      }
      count++;
    });
    this.functionalFormForPatientList.push(new FunctionalFormForPatient(this.selectedtreatmentId,
      this.selectedcurrentIssueId, questionId, answerId, this.personId, localStorage.getItem('loginuserid')));
  }

  saveFunctionalOutcomePatient() {
    if (this.selectedcurrentIssueId === '') {
      this.toastr.error('Please select Current Issue!', 'Error!');
      return;
    }
    if (this.selectedtreatmentId === '') {
      this.toastr.error('Please select Treatment Name!', 'Error!');
      return;
    }
    if (this.functionalFormForPatientList.length !== this.functionalOutcomeFormList.length) {
      this.toastr.error('Please select question of answer!', 'Error!');
      return;
    }
    this.disableSubmitBtn = true;
    this.functionalFormForPatientList.forEach(element => {
      element.currentIssueId = this.selectedcurrentIssueId;
      element.treatmentId = this.selectedtreatmentId;
    });
    this.oncocareDigitalService.saveFunctionalOutcomePatient(this.functionalFormForPatientList).subscribe(
      data => {
        this.toastr.success('Save Successfully!', 'Success!');
        this.functionalFormForPatientList = [];
        this.ngOnInit();
      }
    );
  }
  getFunctionalOutcomePatientById() {
    this.oncocareDigitalService.getFunctionalOutcomePatientByPatientId(this.personId).subscribe(
      data => {
        this.functionalOutcomePatientList = data;
      }
    );
  }

  getFunctionalFormForPatientByIdGroupby() {
    this.oncocareDigitalService.getFunctionalFormForPatientByIdGroupby(this.personId).subscribe(
      data => {
        this.selectedtreatmentId = data[0][2];
        this.selectedcurrentIssueId = data[0][3];
        this.lastAttemptedData = data;
      }
    );
  }

  get validMsg() {
    return this.functionalOutcomePatient.controls;
  }
}
export class FunctionalFormForPatient {
  treatmentId: string;
  currentIssueId: string;
  questionId: string;
  answerId: string;
  personId: string;
  submitedBy: string;
  constructor(treatmentId: string, currentIssueId: string, questionId: string, answerId: string,
    personId: string, submitedBy: string) {
    this.treatmentId = treatmentId;
    this.currentIssueId = currentIssueId;
    this.questionId = questionId;
    this.answerId = answerId;
    this.personId = personId;
    this.submitedBy = submitedBy;
  }
}
