import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tobacco-de-addiction',
  templateUrl: './tobacco-de-addiction.component.html',
  styleUrls: ['./tobacco-de-addiction.component.css']
})
export class TobaccoDeAddictionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
  }

}
