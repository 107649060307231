import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Router } from '@angular/router';
import { ServerapiService } from 'src/app/serverapi.service';
import { Configuration } from 'src/app/app.constants';
import { AppComponent } from 'src/app/app.component';
import { SubscriptionService } from 'src/app/subscription/subscription.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private serverapi: ServerapiService,
    private configuration: Configuration,
    private appComponent: AppComponent,
    private subscriptionService: SubscriptionService,
  ) { }

  ngOnInit() {
    document.documentElement.scrollTop = 0
  }
}
