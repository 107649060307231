import { Injectable } from '@angular/core';


@Injectable()
export class Configuration {
  public SERVER = {
    // Dev
    /* oncocareDigitalApplicationUrl: 'http://localhost:8181/cci', // 'http://localhost:8181/CCI',
    oncocareDatabaseApplicationUrl: 'http://localhost:8181/his', // 'http://localhost:8181/HIS',
    oncocareDigitalAPIUrl: 'http://localhost:8080/',
    oncocareDatabaseAPIUrl: 'http://localhost:8070/HISServer/',
    portalAPIUrl: 'http://localhost:8082',
    databaseRedirectUrl: 'http://localhost:8181/', // 'http://localhost:8181/HIS',
    CCIRedirectUrl: 'http://localhost:8181', */

    // http://oncodigital.com Production
    oncocareDigitalApplicationUrl: 'https://www.oncodigital.com/cci', // 'http://localhost:8181/CCI',
    oncocareDatabaseApplicationUrl: 'https://www.oncodigital.com/his', // 'http://localhost:8181/HIS',
    oncocareDigitalAPIUrl: 'https://www.oncodigital.com/CCIServer/',
    oncocareDatabaseAPIUrl: 'https://www.oncodigital.com/HISServer/',
    portalAPIUrl: 'https://www.oncodigital.com/OncoDigital',
    databaseRedirectUrl: 'https://www.oncodigital.com/', // 'http://localhost:8181/HIS',
    CCIRedirectUrl: 'https://www.oncodigital.com'

    /* oncocareDigitalApplicationUrl: 'http://oncodigital.cahoot.co.in/cci', // 'http://localhost:8181/CCI',
    oncocareDatabaseApplicationUrl: 'http://oncodigital.cahoot.co.in/his', // 'http://localhost:8181/HIS',
    oncocareDigitalAPIUrl: 'http://oncodigital.cahoot.co.in/CCIServer/',
    oncocareDatabaseAPIUrl: 'http://oncodigital.cahoot.co.in/HISServer/',
    portalAPIUrl: 'http://oncodigital.cahoot.co.in/OncoDigital',
    databaseRedirectUrl: 'http://oncodigital.cahoot.co.in/', // 'http://localhost:8181/HIS',
    CCIRedirectUrl: 'http://oncodigital.cahoot.co.in', */

    // Local Deployment
    /* oncocareDigitalApplicationUrl: 'http://localhost:9020/CCI#/doctor',
    oncocareDatabaseApplicationUrl: 'http://localhost:9010/HIS#/app/hospital-menu',
    oncocareDigitalAPIUrl: 'http://localhost:9020/CCIServer/',
    oncocareDatabaseAPIUrl: 'http://localhost:9010/HISServer/',
    portalAPIUrl: 'http://localhost:9030/portalserver',
    databaseRedirectUrl: 'http://localhost:9010',
    CCIRedirectUrl: 'http://localhost:9020', */


    // Prod
    // example  http://oncodigital.com/
    /*  oncocareDigitalApplicationUrl: 'http://oncodigital.com/cci',
     oncocareDatabaseApplicationUrl: 'http://oncodigital.com/his',
     oncocareDigitalAPIUrl: 'http://oncodigital.com/ncciserver/',
     oncocareDatabaseAPIUrl: 'http://oncodigital.com/hisserver/',
     portalAPIUrl: 'http://oncodigital.com/portalserver',
     databaseRedirectUrl: 'http://oncodigital.com/cci',
     CCIRedirectUrl: 'http://oncodigital.com/his', */
  };
}
