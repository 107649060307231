import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Configuration } from './app.constants';
import { CouponCodeMasterComponent } from './coupon-code-master/coupon-code-master.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { DoctorsregistrationComponent } from './doctorsregistration/doctorsregistration.component';
import { LoginComponent } from './login/login.component';
import { MasterlinksComponent } from './masterlinks/masterlinks.component';
import { PatientRegistrationComponent } from './patient-registration/patient-registration.component';
import { RegistrationComponent } from './registration/registration.component';
import { SubscriptionPlanComponent } from './subscription-plan/subscription-plan.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { OauthInterceptor } from './_helpers/oauth.interceptor';
import { LookingformasterComponent } from './_master/lookingformaster/lookingformaster.component';
import { SitemasterComponent } from './_master/sitemaster/sitemaster.component';
import { StagemasterComponent } from './_master/stagemaster/stagemaster.component';
import { SubsiteemasterComponent } from './_master/subsiteemaster/subsiteemaster.component';
import { SubtypemasterComponent } from './_master/subtypemaster/subtypemaster.component';
import { TreatmenthistorymasterComponent } from './_master/treatmenthistorymaster/treatmenthistorymaster.component';
import { TreatmentstatusmasterComponent } from './_master/treatmentstatusmaster/treatmentstatusmaster.component';
import { PaymentComponent } from './payment/payment.component';
import { UserSummaryComponent } from './user-summary/user-summary.component';
import { SubscriptionSummaryComponent } from './subscription-summary/subscription-summary.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailedComponent } from './payment-failed/payment-failed.component';
import { AuthGuard } from './_helpers/auth.guard';
import { LoginSingupComponent } from './login-singup/login-singup.component';
import { DataTablesModule } from 'angular-datatables';
import { MySubscriptionComponent } from './my-subscription/my-subscription.component';
import { PatientListComponent } from './patient-list/patient-list.component';
import { AddEditAppoinmentComponent } from './add-edit-appoinment/add-edit-appoinment.component';
import { AddEditFunctionalOutcomeComponent } from './add-edit-functional-outcome/add-edit-functional-outcome.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { HomePageComponent } from './common/home-page/home-page.component';
import { HeaderAfterLoginComponent } from './common/header-after-login/header-after-login.component';
import { CancerPatientsComponent } from './static-page/cancer-patients/cancer-patients.component';
import { OncologistsComponent } from './static-page/oncologists/oncologists.component';
import { CancerHospitalsComponent } from './static-page/cancer-hospitals/cancer-hospitals.component';
import { CommunityProgrammeComponent } from './static-page/community-programme/community-programme.component';
import { NormalPersonComponent } from './static-page/normal-person/normal-person.component';
import { GovernmentAndRegionalAuthoritiesComponent } from './static-page/government-and-regional-authorities/government-and-regional-authorities.component';
import { LaboratoryPathologyComponent } from './static-page/laboratory-pathology/laboratory-pathology.component';
import { CancerSelfHelpWebsiteComponent } from './static-page/cancer-self-help-website/cancer-self-help-website.component';
import { PersonalisedAssistanceProgrammeComponent } from './static-page/personalised-assistance-programme/personalised-assistance-programme.component';
import { TelemedicineSupportComponent } from './static-page/telemedicine-support/telemedicine-support.component';
import { OnlineTreatmentOpinionOfCancerExpertsComponent } from './static-page/online-treatment-opinion-of-cancer-experts/online-treatment-opinion-of-cancer-experts.component';
import { FindCancerExpertNearYouComponent } from './static-page/find-cancer-expert-near-you/find-cancer-expert-near-you.component';
import { BookSpecificInvestigationsNearYouComponent } from './static-page/book-specific-investigations-near-you/book-specific-investigations-near-you.component';
import { DrugsAndProsthesisComponent } from './static-page/drugs-and-prosthesis/drugs-and-prosthesis.component';
import { PossibilityToAddMoreInFutureComponent } from './static-page/possibility-to-add-more-in-future/possibility-to-add-more-in-future.component';
import { ClinicalDetailsComponent } from './dynemic-form/clinical-details/clinical-details.component';
import { LocalStorageService } from './login/local-storage.service';
import { PrivacyPolicyComponentComponent } from './static-page/privacy-policy-component/privacy-policy-component.component';
import { RefundCancleComponentComponent } from './static-page/refund-cancle-component/refund-cancle-component.component';
import { TermsConditionComponentComponent } from './static-page/terms-condition-component/terms-condition-component.component';
import { ContactUsComponent } from './static-page/contact-us/contact-us.component';
import { AboutUsComponent } from './static-page/about-us/about-us.component';
import { OnlineSecondOpinionComponent } from './static-page/online-second-opinion/online-second-opinion.component';
import { TobaccoDeAddictionComponent } from './static-page/tobacco-de-addiction/tobacco-de-addiction.component';
import { CancerAwarenessTalksComponent } from './static-page/cancer-awareness-talks/cancer-awareness-talks.component';
import { SelfScreeningStaticPageComponent } from './static-page/self-screening-static-page/self-screening-static-page.component';
import { MassDigitalPreScreeningComponent } from './static-page/mass-digital-pre-screening/mass-digital-pre-screening.component';
import { DistrictCancerControlProgrammeComponent } from './static-page/district-cancer-control-programme/district-cancer-control-programme.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ResetPasswordDialogComponent } from './reset-password-dialog/reset-password-dialog.component';
import { ChahgePasswordComponent } from './chahge-password/chahge-password.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { PatientSummaryComponent } from './patient-summary/patient-summary.component';
import { PatientPaymentComponent } from './patient-payment/patient-payment.component';
import { DiscountCodeComponent } from './discount-code/discount-code.component';

const routes: Routes = [
  {
    path: 'patient-registration/:personId/:patientId/:registerType',
    component: PatientRegistrationComponent,
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomePageComponent,
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'my_subscription',
    component: MySubscriptionComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'admin/subscription_plan',
    component: SubscriptionPlanComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'register/:type',
    component: RegistrationComponent
  },
  {
    path: 'admin/doctors/:doctorRegisterType',
    component: DoctorsregistrationComponent
  },
  {
    path: 'master/stage_master',
    component: StagemasterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'master/site_master',
    component: SitemasterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'master/sub_site_master',
    component: SubsiteemasterComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'master/sub_type_master',
    component: SubtypemasterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'master/looking_for_master',
    component: LookingformasterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'master/treatment_history_master',
    component: TreatmenthistorymasterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'master/treatment_status_master',
    component: TreatmentstatusmasterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/masters_section',
    component: MasterlinksComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admin/coupon-code',
    component: CouponCodeMasterComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'show-subscription-plan',
    component: SubscriptionComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'payment/:loginUserId/:subscriptionId',
    component: PaymentComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'payment-success/:paymentId/:subscriptionId',
    component: PaymentSuccessComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'payment-failed/:paymentId/:subscriptionId',
    component: PaymentFailedComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'login-signup/:subscriptionId',
    component: LoginSingupComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'patient-list',
    component: PatientListComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'header-demo',
    component: HeaderComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'cancer-patient',
    component: CancerPatientsComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'oncologies',
    component: OncologistsComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'cancer-hospital',
    component: CancerHospitalsComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'community-programme',
    component: CommunityProgrammeComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'normal-person',
    component: NormalPersonComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'government-regional-authorities',
    component: GovernmentAndRegionalAuthoritiesComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'laboratory-pathology',
    component: LaboratoryPathologyComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'cancer-self-help-website',
    component: CancerSelfHelpWebsiteComponent,
    // canActivate: [AuthGuard]
  },
  {
    path: 'personalised-assistance-programme',
    component: PersonalisedAssistanceProgrammeComponent
  },
  {
    path: 'telemedicine-support',
    component: TelemedicineSupportComponent
  },
  {
    path: 'online-treatment-opinion-of-cancer-experts',
    component: OnlineTreatmentOpinionOfCancerExpertsComponent
  },
  {
    path: 'find-cancer-expert-near-you',
    component: FindCancerExpertNearYouComponent,
  },
  {
    path: 'book-specific-investigations-near-you',
    component: BookSpecificInvestigationsNearYouComponent,
  },
  {
    path: 'drugs-and-prosthesis',
    component: DrugsAndProsthesisComponent,
  },
  {
    path: 'possibility-to-add-more-in-future',
    component: PossibilityToAddMoreInFutureComponent,
  },
  {
    path: 'clinical-details/:patientId',
    component: ClinicalDetailsComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponentComponent
  },
  {
    path: 'terms-conditions',
    component: TermsConditionComponentComponent
  },
  {
    path: 'refund-cancel',
    component: RefundCancleComponentComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  },
  {
    path: 'online-second-opinion',
    component: OnlineSecondOpinionComponent
  },
  {
    path: 'tobacco-de-addiction',
    component: TobaccoDeAddictionComponent
  },
  {
    path: 'cancer-awareness-talks',
    component: CancerAwarenessTalksComponent
  },
  {
    path: 'self-screening-static-page',
    component: SelfScreeningStaticPageComponent
  },
  {
    path: 'mass-digital-pre-screening',
    component: MassDigitalPreScreeningComponent
  },
  {
    path: 'district-cancer-control-programme',
    component: DistrictCancerControlProgrammeComponent
  },
  {
    path: 'change-password',
    component: ChahgePasswordComponent
  },
  {
    path: 'patient-payment',
    component: PatientPaymentComponent
  },
  {
    path: 'admin/discount-code',
    component: DiscountCodeComponent
  },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    DoctorsregistrationComponent,
    DashboardComponent,
    SubscriptionComponent,
    SubscriptionPlanComponent,
    DeleteDialogComponent,
    StagemasterComponent,
    SubsiteemasterComponent,
    SitemasterComponent,
    SubtypemasterComponent,
    LookingformasterComponent,
    TreatmenthistorymasterComponent,
    TreatmentstatusmasterComponent,
    MasterlinksComponent,
    PatientRegistrationComponent,
    CouponCodeMasterComponent,
    PaymentComponent,
    UserSummaryComponent,
    SubscriptionSummaryComponent,
    PaymentSuccessComponent,
    PaymentFailedComponent,
    LoginSingupComponent,
    PatientListComponent,
    MySubscriptionComponent,
    AddEditAppoinmentComponent,
    AddEditFunctionalOutcomeComponent,
    HeaderComponent,
    FooterComponent,
    HomePageComponent,
    HeaderAfterLoginComponent,
    CancerPatientsComponent,
    OncologistsComponent,
    CancerHospitalsComponent,
    CommunityProgrammeComponent,
    NormalPersonComponent,
    GovernmentAndRegionalAuthoritiesComponent,
    LaboratoryPathologyComponent,
    CancerSelfHelpWebsiteComponent,
    PersonalisedAssistanceProgrammeComponent,
    TelemedicineSupportComponent,
    OnlineTreatmentOpinionOfCancerExpertsComponent,
    FindCancerExpertNearYouComponent,
    BookSpecificInvestigationsNearYouComponent,
    DrugsAndProsthesisComponent,
    PossibilityToAddMoreInFutureComponent,
    ClinicalDetailsComponent,
    PrivacyPolicyComponentComponent,
    RefundCancleComponentComponent,
    TermsConditionComponentComponent,
    ContactUsComponent,
    AboutUsComponent,
    OnlineSecondOpinionComponent,
    TobaccoDeAddictionComponent,
    CancerAwarenessTalksComponent,
    SelfScreeningStaticPageComponent,
    MassDigitalPreScreeningComponent,
    DistrictCancerControlProgrammeComponent,
    ResetPasswordDialogComponent,
    ChahgePasswordComponent,
    ForgetPasswordComponent,
    PatientSummaryComponent,
    PatientPaymentComponent,
    DiscountCodeComponent,
  ],
  imports: [
    NgxUiLoaderModule,
    CKEditorModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    DataTablesModule,
    MatDialogModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(routes, { useHash: true })
  ],
  entryComponents: [DeleteDialogComponent, AddEditAppoinmentComponent, AddEditFunctionalOutcomeComponent , ResetPasswordDialogComponent , ForgetPasswordComponent],
  providers: [
    Configuration,
    LocalStorageService,
    { provide: HTTP_INTERCEPTORS, useClass: OauthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
