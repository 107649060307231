import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Configuration } from 'src/app/app.constants';
import { ServerapiService } from 'src/app/serverapi.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-oncologists',
  templateUrl: './oncologists.component.html',
  styleUrls: ['./oncologists.component.css']
})
export class OncologistsComponent implements OnInit {
  @ViewChild(DataTableDirective , {static: false})
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  portalUrl = '';
  hospitalAndDoctorList;
  countryId = '';
  countryList;
  stateId = '';
  stateList;
  districtId = '';
  districtList;
  specialityId = '';
  specialityList;
  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private serverAPI: ServerapiService,
  ) {
    this.portalUrl = this.configuration.SERVER.portalAPIUrl;
  }

  ngOnInit() {
    document.documentElement.scrollTop = 0;
    this.getAllCountries();
    this.getSpecialityList();
    this.gethospitalAndDoctorList();
  }

  gethospitalAndDoctorListByClick() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
  }


  gethospitalAndDoctorList() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.countryId = this.countryId;
        dataTablesParameters.stateId = this.stateId;
        dataTablesParameters.districtId = this.districtId;
        dataTablesParameters.specialityId = this.specialityId;
        this.http
          .post<DataTablesResponse>(
            this.portalUrl + '/api/v1/doctor/getHospitalAndDoctorByCriterion',
            dataTablesParameters, {}
          ).subscribe(resp => {
            this.hospitalAndDoctorList = resp.data;
            console.log(this.hospitalAndDoctorList);
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: [],
            });
          });
      },
      columns: [{ data: 'hospitalId' }, { data: 'hospitalName' }]
    };
  }

  getAllCountries() {
    this.serverAPI.getCountries().subscribe(
      data => {
        this.countryList = data;
      });
  }

  getStateByCountryId() {
    this.serverAPI.getStates(this.countryId).subscribe(
      data => {
        this.stateList = data;
      });
  }

  getDistrictByStateId() {
    this.serverAPI.getDistricts(this.stateId).subscribe(
      data => {
        this.districtList = data;
      });
  }

  getSpecialityList() {
    this.serverAPI.getSpeciality().subscribe(
      data => {
        this.specialityList = data;
      }
    );
  }
}
