import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { OncocareDigitalService } from '../oncocaredigitalservice.service';
import { PatientService } from '../patient-list/patient-service.service';

@Component({
  selector: 'app-add-edit-appoinment',
  templateUrl: './add-edit-appoinment.component.html',
  styleUrls: ['./add-edit-appoinment.component.css']
})
export class AddEditAppoinmentComponent implements OnInit {
  toDoMaster;
  toDoMasterList;
  headingMasterList;
  activityMasterList;
  pendingToDoList;
  submitted = false;
  todayDate = new Date().toISOString().split('T')[0];
  parentRedirectionURL;
  role;
  headingAndActivityFor;
  showHideForm = false;
  personData;
  message;
  patientId;
  personId;
  hospitalId;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private activeRouter: ActivatedRoute,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private oncocareDigitalService: OncocareDigitalService,
    private patientService: PatientService) { }

  toDoListForm = this.fb.group({
    headingMaster: ['', Validators.required],
    activityMaster: ['', Validators.required],
    doDate: ['', Validators.required],
    comment: [''],
    doctorName: [localStorage.getItem('chiefAdmin')],
    loginUserId: [localStorage.getItem('currentLoingUserId')],
    doctorId: [localStorage.getItem('doctorId')],
    hospitalId: [localStorage.getItem('hospitalIdPortal')],
  });

  ngOnInit() {
    this.personId = this.data.personId;
    this.patientId = this.data.patientId;
    this.hospitalId = localStorage.getItem('hospitalIdPortal');
    this.headingAndActivityFor = 'patient';
    this.getAllHeadingMaster();
    // this.getPersonById();
    this.getToDoListByStatus();
    this.getAllToDoListByPatientId();
  }


  /*  getPersonById() {
     this.oncocareDigitalService.getPersonById(this.personId).subscribe(data => {
       this.personData = data;
       if (this.personData.userType === 'patient') {
         this.headingAndActivityFor = 'patient';
       } else {
         this.headingAndActivityFor = 'admin';
       }
       this.getAllHeadingMaster();
     });
   } */

  get validMsg() {
    return this.toDoListForm.controls;
  }

  saveToDoList() {
    this.submitted = true;
    if (this.toDoListForm.invalid) {
      return;
    }
    const toDoListData = this.toDoListForm.value;
    toDoListData.isActive = 1;
    toDoListData.headingMaster = parseInt(toDoListData.headingMaster, 10);
    toDoListData.activityMaster = parseInt(toDoListData.activityMaster, 10);
    toDoListData.doDate = Date.parse(toDoListData.doDate);
    toDoListData.patientId = this.personId;
    toDoListData.status = 1;
    if (this.toDoMaster) {
      toDoListData.appointmentId = this.toDoMaster['appointmentId'];
    } else {
      toDoListData.appointmentId = 0;
    }
    this.patientService.saveToDoList(toDoListData).subscribe(
      toDoList => {
        this.getAllToDoListByPatientId();
        this.getToDoListByStatus();
        this.toDoListForm.controls.activityMaster.setValue('');
        this.toDoListForm.controls.headingMaster.setValue('');
        this.toDoListForm.controls.doDate.setValue('');
        this.toDoListForm.controls.comment.setValue('');
        if (this.toDoMaster) {
          this.toastr.success('Update Successfully!', 'Update!');
        } else {
          this.toastr.success('Save Successfully!', 'Success!');
        }
        this.toDoMaster = '';
      }
    );
  }

  getAllToDoListByPatientId() {
    this.patientService.getAllAppoinmentByPatientAndHospitalId(this.personId, this.hospitalId).subscribe(data => {
      this.toDoMasterList = data;
    });
  }

  getToDoListById(id) {
    this.patientService.findByAppointmentId(id).subscribe(data => {
      this.toDoMaster = data;
      this.toDoListForm.controls.headingMaster.setValue(data['headingId']);
      this.getAllActivityMasterByHeadingId();
      this.toDoListForm.controls.activityMaster.setValue(data['activityId']);
      this.toDoListForm.controls.doDate.setValue(this.datePipe.transform(data['startDateTime'], 'yyyy-MM-dd'));
      this.toDoListForm.controls.comment.setValue(data['caseReason']);
      this.pendingToDoList = '';
    });
  }

  deleteToDoListById(id) {
    const message = 'Once you Delete, all your data will be removed. Continue ?';
    const dialogRef = this.dialog.open(DeleteDialogComponent, { data: { message, btn: 'Delete' } });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.patientService.updateAppointmentStatusById(id, 0).subscribe(
          data => {
            this.toastr.success('Delete Successfully!', 'Success!');
            this.getAllToDoListByPatientId();
            this.getToDoListByStatus();
          }
        );
      }
    });
  }

  getAllHeadingMaster() {
    this.oncocareDigitalService.getAllHeadingMasterByheadingFor(this.headingAndActivityFor).subscribe(data => {
      this.headingMasterList = data;
    });
  }

  getAllActivityMasterByHeadingId() {
    this.oncocareDigitalService.getAllActivityMasterByHeadingIdAndActivityFor(this.toDoListForm.controls.headingMaster.value,
      this.headingAndActivityFor).subscribe(data => {
        this.activityMasterList = data;
      });
  }

  getToDoListByStatus() {
    this.oncocareDigitalService.getToDoListByStatus(1, this.personId).subscribe(data => {
      this.pendingToDoList = data;
      if (this.role === 'null') {
        if (this.pendingToDoList.length > 0) {
          this.showHideForm = true;
        } else {
          this.showHideForm = false;
        }
      } else {
        this.showHideForm = false;
      }
    });
  }
  parentNavigataion() {
    this.router.navigateByUrl(this.parentRedirectionURL);
  }


  statusChange(id) {
    const message = 'Are you sure want to complete this calender activity ?.';
    const dialogRef = this.dialog.open(DeleteDialogComponent, { data: { message, btn: 'Activity Complete' } });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.patientService.updateAppointmentStatusById(id, 2).subscribe(
          data => {
            this.toastr.success('Status Change Successfully!', 'Success!');
            this.ngOnInit();
          }
        );
      }
    });
  }
}
