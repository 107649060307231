import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '../app.constants';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};
@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  portalUrl = '';
  constructor(private http: HttpClient, private configuration: Configuration) {
    this.portalUrl = this.configuration.SERVER.portalAPIUrl;
  }


  postPaytmPayment(data) {
    return this.http.post(this.portalUrl + '/api/v1/paytmPayment', data, httpOptions).pipe();
  }



  paytmRedirection(data) {
    return this.post(data);
  }


  post(obj) {
    const mapForm = document.createElement('form');
    mapForm.target = '_self';
    mapForm.method = 'POST'; // or "post" if appropriate
    mapForm.action = obj.PAYTM_URL;
    const mapInputs = document.createElement('input');
    mapInputs.type = 'hidden';
    mapInputs.name = 'CHECKSUMHASH';
    mapInputs.setAttribute('value', obj['checkSum']);
    mapForm.appendChild(mapInputs);
    Object.keys(obj.parameters).forEach(function (param) {
      const mapInput = document.createElement('input');
      mapInput.type = 'hidden';
      mapInput.name = param;
      mapInput.setAttribute('value', obj.parameters[param]);
      console.log(mapInput);
      mapForm.appendChild(mapInput);
    });
    console.log(mapForm);
    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  getPaytmResponseByPaytmResponseId(paytmResponseId) {
    return this.http.get(this.portalUrl + '/api/v1/getPaytmResponseByPaytmResponseId/' + paytmResponseId);
  }
}


