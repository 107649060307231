import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CouponCodeService } from '../coupon-code-master/coupon-code.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-discount-code',
  templateUrl: './discount-code.component.html',
  styleUrls: ['./discount-code.component.css']
})
export class DiscountCodeComponent implements OnInit {
  error;
  submitted = false;
  disableSubmitBtn = false;
  coupponCodePatientMaster;
  coupponCodePatientList;
  message;
  constructor(
    private fb: FormBuilder,
    private couponCodeService: CouponCodeService,
    private toastr: ToastrService,
    private dialog: MatDialog,
  ) { }

  couponCodePatientForm = this.fb.group({
    coupponCode: ['', Validators.required],
  });

  ngOnInit() {
    this.getAllCouponCodePlan();
  }

  submit() {
    this.submitted = true;
    if (this.couponCodePatientForm.invalid) {
      return;
    }
    this.disableSubmitBtn = true;
    const data = this.couponCodePatientForm.value;
    if (this.coupponCodePatientMaster) {
      data.discountCodeId = this.coupponCodePatientMaster.id;
    }
    this.couponCodeService.saveCouponCodePatient(data).subscribe(
      response => {
        this.getAllCouponCodePlan();
        this.couponCodePatientForm.controls.coupponCode.setValue('');
        this.disableSubmitBtn = false;
        if (this.coupponCodePatientMaster) {
          this.toastr.success('Update Successfully!', 'Update!');
        } else {
          this.toastr.success('Save Successfully!', 'Success!');
        }
        this.coupponCodePatientMaster = '';
        this.getAllCouponCodePlan();
      },
      e => {
        this.error = e.error.message;
      });
  }

  getAllCouponCodePlan() {
    this.couponCodeService.getAllCouponCodePatientList().subscribe(
      response => {
        this.coupponCodePatientList = response;
      },
      e => {
        this.error = e.error.message;
      });
  }

  getCouponCodePlanMasterById(couponCodeId) {
    this.couponCodeService.getCouponCodePlanMasterById(couponCodeId).subscribe(response => {
      this.coupponCodePatientMaster = response;
      this.couponCodePatientForm.controls.coupponCode.setValue(this.coupponCodePatientMaster.coupponCode);
    });
  }

  deleteDiscountCodePlanById(id) {
    this.message = 'Once you Delete, all your data will be removed. Continue ?';
    const dialogRef = this.dialog.open(DeleteDialogComponent, { data: { message: this.message, btn: 'Delete', btn2: 'Cancel' } });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.couponCodeService.deleteCouponCodePlanById(id).subscribe(
          data => {
            this.toastr.success('Delete Successfully!', 'Success!');
            this.getAllCouponCodePlan();
          }
        );
      }
    });
  }
}
